import { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from '../api/hooks'
import queryString from 'query-string'

const useOrganizations = (near) => {
  const [request, { result, loading, called }] = useFetch('Error fetching organizations')
  const getOrgs = useCallback(async () => {
    return request({
      method: 'get',
      url: `/task_organizations?${queryString.stringify({ near }, { skipNull: true, skipEmptyString: true })}`,
    })
  }, [near, request])

  useEffect(() => {
    getOrgs()
  }, [getOrgs])

  return useMemo(
    () => ({
      organizations: result || [],
      loading: loading || !called,
    }),
    [result, loading, called],
  )
}

export default useOrganizations
