import React, { useMemo } from 'react'
import Loading from '../components/Loading'
import useTask from '../hooks/useTask'
import TaskStatus from '../utils/TaskStatus'
import UnassignedTaskDetails from './UnassignedTaskDetails'
import AssignedTaskDetails from './AssignedTaskDetails'
import ActiveTaskDetails from './ActiveTaskDetails'
import * as _ from 'lodash'
import { Redirect } from 'react-router'
import RequesterTaskDetails from './RequesterTaskDetails'
import useTaskHistory from '../hooks/useTaskHistory'
import { taskIsCancelled, taskIsNotAccepted } from '../utils/taskUtils'
import { queryStringToObject } from 'civic-champs-shared/core/utils/helpers'

const useTaskDetails = ({ user, location, task, loading }) =>
  useMemo(() => {
    if (loading) {
      return { isVolunteer: false, isRequesterOrRecipient: false, cancelled: false, notAccepted: false }
    }

    // user relationship
    const { view } = queryStringToObject(_.get(location, 'search', ''))
    const isRecipientView = view === 'recipient'
    const isVolunteerView = view === 'volunteer'
    const userId = _.get(user, 'id')
    const isRequester = userId === _.get(task, 'requester.id')
    const isRecipient = userId === _.get(task, 'recipient.id')
    const isVolunteer = !isRecipientView && userId === _.get(task, 'volunteer.id')
    const isRequesterOrRecipient = !isVolunteerView && (isRequester || isRecipient)

    // task status
    const cancelled = taskIsCancelled(task)
    const notAccepted = taskIsNotAccepted(task)
    return { isVolunteer, isRequesterOrRecipient, cancelled, notAccepted }
  }, [user, location, task, loading])

export default function TaskInfo(props) {
  const { user, location = {}, loading: userLoading, match } = props
  const taskId = match.params.id
  const { task, loading: taskLoading, refresh } = useTask(taskId)
  const { taskHistory, loading: taskHistoryLoading } = useTaskHistory(taskId)
  const loading = userLoading || taskLoading || taskHistoryLoading
  const { isVolunteer, isRequesterOrRecipient, cancelled, notAccepted } = useTaskDetails({
    user,
    location,
    task,
    loading,
  })
  if (loading) return <Loading />
  // defunct tasks
  if (cancelled || notAccepted) {
    return <div>This task was ${cancelled ? 'cancelled' : 'not accepted'}</div>
  }
  // requester/recipient view
  if (!isVolunteer && isRequesterOrRecipient) {
    return <RequesterTaskDetails task={task} taskHistory={taskHistory} />
  }
  // blocked
  if (!isVolunteer && task.taskStatus !== TaskStatus.Accepted) {
    return <Redirect to="/lend-dashboard" />
  }
  // volunteer view
  if (task.taskStatus === TaskStatus.Accepted) {
    return <UnassignedTaskDetails task={task} />
  } else if (task.taskStatus === TaskStatus.Scheduled) {
    return <AssignedTaskDetails task={task} refreshTask={refresh} />
  } else if (task.taskStatus === TaskStatus.InProgress) {
    return <ActiveTaskDetails task={task} />
  } else {
    // not a likely use case, but enabling if isVolunteer and isRequesterOrRecipient
    return <RequesterTaskDetails task={task} taskHistory={taskHistory} disabled={!isRequesterOrRecipient} />
  }
}
