import React from 'react'
import useTaskVolunteeringRoles from '../../../hooks/useTaskVolunteeringRoles'
import RadioSelect from '../../../components/RadioSelect'
import StepContainer from './StepContainer'

export default function ChooseServiceType(props) {
  const { nextStep, value, organization, onChange } = props
  const { roles: services, loading } = useTaskVolunteeringRoles(organization.id)

  return (
    <StepContainer
      header="Type of Service"
      subHeader="What type of help is needed?"
      disabled={!value}
      nextStep={nextStep}
      loading={loading}
    >
      <RadioSelect options={services} value={value} onChange={onChange} />
    </StepContainer>
  )
}
