import React from 'react'
import { Button, makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    button: {
        width: '324px',
        height: '65px',
        backgroundColor: '#5C8DE8FA',
        borderColor: '#3D3D3D',
        borderWidth: '1px',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
        textDecoration: 'none',
        marginBottom: '34px',
        marginTop: '30px',
        alignSelf: 'center'
    },
    buttonText: {
        color: 'white',
    },
}))

export default function NextButton(props) {
    const { text } = props
    const classes = useStyles()

    return (
        <Button
            className={classes.button}
            variant="contained"
            { ...props }
        >
            <div className={classes.buttonText}><b>{ text || 'Next' }</b></div> 
       </Button>
    )
}