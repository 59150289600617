import { GetOnMountProps, Refresh, Status } from './fetchRefactoredSchema'
import { useCallback, useMemo } from 'react'
import useFetchRefactored from './useFetchRefactored'
import { useMount } from 'react-use'

export default function useGetOnMount<T>(props: GetOnMountProps<T>): [Refresh<T>, Status<T>] {
  const { errorMessage, successMessage, emptyValue, onSuccess, url, config, onError, isPublicRoute } = props
  const [request, status] = useFetchRefactored({ errorMessage, successMessage, emptyValue, isPublicRoute })
  const refresh = useCallback(async () => {
    return request({ url, onError, onSuccess, config, method: 'get' })
  }, [request, url, onError, onSuccess, config])

  useMount(() => refresh())

  return useMemo(() => [refresh, { ...status, loading: status.loading || !status.called }], [refresh, status])
}
