export const AUTH_INITIALIZED = 'auth.initialized';
export const SIGNED_IN = 'auth.signed-in';
export const SIGNED_OUT = 'auth.signed-out';

//TODO This is not *really* part of auth, more part of app?
// export const START_MANAGING_ORG = 'auth.manage-organization.start'

export const authInitialized = (user) => ({
    type: AUTH_INITIALIZED,
    payload: { user }
});

export const signedIn = (user) => ({
    type: SIGNED_IN,
    payload: { user }
});

export const signedOut = () => ({ type: SIGNED_OUT });

// export const startManagingOrg = (organization) => ({
//     type: START_MANAGING_ORG,
//     payload: { organization }
// })