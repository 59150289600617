import moment from 'moment-timezone'
import TaskStatus from './TaskStatus'
import * as _ from 'lodash'

export const taskIsToday = (task) => moment(task.startTime).tz(task.organization.timeZone).isSame(moment(), 'day')

export const taskIsExpiring = (task) => {
  if (taskIsDone(task)) {
    return false
  }
  const hoursToExpiration = moment().diff(moment(task.overdueAt).tz(task.organization.timeZone), 'hours')
  return hoursToExpiration <= 24 && hoursToExpiration > 0
}

export const taskIsActive = (task) => taskIsInProgress(task) || (taskIsCompleted(task) && !task.recipientFeedback)

export const taskIsCurrent = (task) => moment(task.overdueAt).tz(task.organization.timeZone).isAfter(moment())

export const taskIsOverdue = (task) =>
  !taskIsDone(task) && moment().diff(moment(task.overdueAt).tz(task.organization.timeZone), 'hours') < 0

const taskIs = (statusArr) => (task) => _.castArray(statusArr).includes(task.taskStatus)
export const taskIsAccepted = taskIs(TaskStatus.Accepted)
export const taskIsInProgress = taskIs(TaskStatus.InProgress)
export const taskIsScheduled = taskIs(TaskStatus.Scheduled)
export const taskIsCompleted = taskIs([TaskStatus.ClaimedCompleted, TaskStatus.Completed])
export const taskIsNotAccepted = taskIs(TaskStatus.NotAccepted)
export const taskIsCancelled = taskIs([TaskStatus.Canceled, TaskStatus.NotCompleted])
export const taskIsDone = taskIs([
  TaskStatus.ClaimedCompleted,
  TaskStatus.Completed,
  TaskStatus.Canceled,
  TaskStatus.NotAccepted,
])

export const TaskStatusDisplayNames = {
  [TaskStatus.InReview]: 'Waiting for Review',
  [TaskStatus.DetailsRequired]: 'Needs More Details',
  [TaskStatus.NotAccepted]: 'Declined',
  [TaskStatus.Accepted]: 'Approved',
  [TaskStatus.Scheduled]: 'Scheduled',
  [TaskStatus.Canceled]: 'Canceled',
  [TaskStatus.InProgress]: 'In Progress',
  [TaskStatus.ClaimedCompleted]: 'Completed',
  [TaskStatus.Completed]: 'Completed (Admin)',
  [TaskStatus.NotCompleted]: 'Canceled (Admin)',
}
