import * as React from 'react'
import { BaseSyntheticEvent } from 'react'
import InputComponent from '../../base/default-input'
import QuestionSkeleton from '../question-skeleton'
import { QuestionType, Question, QuestionFormBaseProps } from "../../../../types"

interface InputQuestionProps extends QuestionFormBaseProps {
  onChange: (value: string, item: Question) => void
  onBlur: () => void
}

const InputQuestion = (props: InputQuestionProps) => {
  const { item, onChange, error, onBlur } = props

  if (item.disabled) {
    return null
  }

  const handleChange = (item: Question) => (event: BaseSyntheticEvent) => {
    onChange(event.target.value, item)
  }

  return (
    <QuestionSkeleton questionTitle={item.question} error={error} isRequired={item.isRequired}>
      <InputComponent
        isInteger={item.questionType === QuestionType.NUMBER}
        type={item.questionType === QuestionType.NUMBER ? 'number' : 'text'}
        value={item.response}
        onChange={handleChange(item)}
        onBlur={onBlur}
      />
    </QuestionSkeleton>
  )
}

export default InputQuestion
