import React from 'react'
import useOrganizations from '../../../hooks/useOrganizations'
import * as _ from 'lodash'
import RadioSelect from '../../../components/RadioSelect'
import StepContainer from './StepContainer'

export default function ChooseOrganization(props) {
  const { nextStep, value, longitude, latitude, onChange } = props
  const near = longitude && latitude ? [latitude, longitude] : null
  const { organizations, loading } = useOrganizations(near)
  return (
    <StepContainer
      header="Organization"
      subHeader="Find An Organization"
      disabled={!value}
      nextStep={nextStep}
      loading={loading}
    >
      <RadioSelect options={organizations} value={value} onChange={onChange} />
      {_.isEmpty(organizations) && <div>Sorry. There are no organizations in your area</div>}
    </StepContainer>
  )
}
