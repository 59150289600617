import * as React from 'react'
import { compose, withProps } from 'recompose'
import { withGoogleMap, GoogleMap, Marker, Circle } from 'react-google-maps'
import { RefObject } from 'react'

const GOOGLE_MAP_PARAMS = {
  containerElement: <div style={{ height: `100%` }} />,
  mapElement: <div style={{ height: `100%` }} />,
}

interface ReactGoogleMapProps {
  coordinates: number[]
  geofenceRadius?: number
  zoom?: number
}

class RenderGoogleMapComponent extends React.Component<ReactGoogleMapProps> {
  componentDidMount() {
    this.getFitBounds()
  }

  componentDidUpdate(prevProps: ReactGoogleMapProps) {
    if (this.props.geofenceRadius !== prevProps.geofenceRadius) {
      this.getFitBounds()
    }
  }

  private mapRef: RefObject<GoogleMap> = React.createRef()
  private circleRef: RefObject<Circle> = React.createRef()

  render() {
    const { coordinates = [], geofenceRadius, zoom = 14 } = this.props

    const [lat, lng] = coordinates

    const defaultLat = 39.172629 // The default LAT for centering map
    const defaultLng = -86.536931 // The default LNG for centering map

    const isHaveCoordinates = !!coordinates.length

    const markerPosition = { lat, lng }

    const position = { lat: lat || defaultLat, lng: lng || defaultLng }
    const circleColor = '#ffd600'

    const circleOptions = {
      center: markerPosition,
      strokeColor: circleColor,
      strokeOpacity: 0.5,
      strokeWeight: 0,
      fillColor: circleColor,
      fillOpacity: 0.5,
    }

    return (
      <GoogleMap ref={this.mapRef} defaultOptions={{ disableDefaultUI: true }} zoom={zoom} center={position}>
        {isHaveCoordinates && geofenceRadius && (
          <Circle ref={this.circleRef} defaultRadius={0} radius={this.props.geofenceRadius} options={circleOptions} />
        )}
        {isHaveCoordinates && <Marker position={markerPosition} />}
      </GoogleMap>
    )
  }

  private getFitBounds = () => {
    if (this.mapRef.current && this.circleRef.current) {
      const circle = this.circleRef.current
      const bounds = circle.getBounds()
      this.mapRef.current.fitBounds(bounds)
    }
  }
}

const ReactGoogleMap = compose<ReactGoogleMapProps, ReactGoogleMapProps>(
  withProps(GOOGLE_MAP_PARAMS),
  withGoogleMap,
)(RenderGoogleMapComponent)

export default ReactGoogleMap
