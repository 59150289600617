import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(theme => ({
    centerContent: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    }
}))

export default function CenterContent(props) {
    const { children } = props
    const classes = useStyles()

    return (
        <div className={classes.centerContent}>
            {children}
        </div>
    )
}