import { useFetch } from '../api/hooks'
import { useCallback, useEffect } from 'react'

const useTaskTimeslots = (taskId) => {
  const [request, { result: timeslots, loading, called }] = useFetch('Error fetching timeslots')

  const getTimeslots = useCallback(async () => {
    return request({
      method: 'get',
      url: `/task/${taskId}/timeslots`,
    })
  }, [taskId, request])

  useEffect(() => {
    getTimeslots()
  }, [getTimeslots])

  return {
    timeslots: timeslots || [],
    loading: loading || !called,
    refresh: getTimeslots,
  }
}

export default useTaskTimeslots
