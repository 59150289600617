import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { stepOneSchema } from './schemas'
import { Field, Formik, Form } from 'formik'
import PasswordField from '../../../components/PasswordField'
import { TextField } from 'formik-material-ui'
import PhoneField from '../../../components/PhoneField'

function StepOne(props) {
  const { onSubmit, classes, profile, emailIsPrimary, onBack } = props
  return (
    <Formik
      initialValues={{
        email: profile.email,
        phoneNumber: profile.phoneNumber,
        password: profile.password,
        passwordConfirm: profile.password,
      }}
      validationSchema={stepOneSchema(emailIsPrimary)}
      onSubmit={(values) => onSubmit(stepOneSchema(emailIsPrimary).cast(values))}
      validateOnBlur
    >
      {({ isValid }) => (
        <Form>
          <Grid container spacing={2} direction="column" justify="center" alignItems="center">
            {emailIsPrimary && (
              <>
                <div className={classes.title}>
                  <b>Email*</b>
                </div>
                <Field
                  component={TextField}
                  type="email"
                  name="email"
                  variant="outlined"
                  className={classes.input}
                  placeholder="Email"
                />
              </>
            )}
            {!emailIsPrimary && (
              <>
                <div className={classes.title}>
                  <b>Phone Number*</b>
                </div>
                <Field
                  component={PhoneField}
                  name="phoneNumber"
                  variant="outlined"
                  className={classes.input}
                  placeholder="Phone Number"
                />
              </>
            )}
            <div className={classes.title}>
              <b>Password*</b>
            </div>
            <Field
              component={PasswordField}
              name="password"
              variant="outlined"
              className={classes.input}
              placeholder="Password"
            />
            <div className={classes.title}>
              <b>Confirm Password*</b>
            </div>
            <Field
              component={PasswordField}
              name="passwordConfirm"
              variant="outlined"
              placeholder="Confirm Password"
              className={classes.input}
            />
            <Button className={classes.buttonLogin} variant="contained" disabled={!isValid} type="submit">
              <div className={classes.loginText}>
                <b>Next</b>
              </div>
            </Button>
            <Button variant="text" color="primary" onClick={onBack}>
              Back
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default StepOne
