import Loading from '../../components/Loading'
import Content from '../../components/Content'
import { IconButton, makeStyles } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Field, Form, Formik } from 'formik'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import StepContainer from '../../request/components/Steps/StepContainer'
import NextButton from '../../components/NextButton'
import { TextField } from 'formik-material-ui'
import { useCompleteTask } from '../../hooks/useTaskEvents'
import yup from '../../utils/yup'
import VolunteerRatingField from '../VolunteerRatingField'
import UploadImageStep from './UploadImageStep'
import TaskDetails from '../TaskDetails'

const initialValues = {
  volunteerFeedbackReflection: '',
  volunteerFeedbackRating: null,
  volunteerFeedbackImageId: null,
}

const useStyles = makeStyles(() => ({
  input: {
    width: '324px',
    height: '354px',
    borderColor: '#707070',
  },
}))

const validationSchema = yup.object({
  volunteerFeedbackReflection: yup.string().min(2).required(),
  volunteerFeedbackRating: yup.number().oneOf([-1, -0.5, 0.5, 1]).required(),
})

export default function CompleteTask(props) {
  const { task, comment } = props
  const [step, setStep] = useState(1)
  const [volunteerImage, setVolunteerImage] = useState(null)
  const [completeTask, completeTaskLoading] = useCompleteTask()
  const history = useHistory()
  const classes = useStyles()

  const handleFormikSubmit = async (values) => {
    const payload = {
      volunteerFeedbackReflection: values.volunteerFeedbackReflection,
      volunteerFeedbackRating: values.volunteerFeedbackRating,
      volunteerFeedbackImageId: volunteerImage.id,
      comment,
    }
    await completeTask(task, payload)
    history.push('/lend-dashboard')
  }

  const submitImage = (image) => {
    setVolunteerImage(image)
    setStep(2)
  }

  if (completeTaskLoading) {
    return (
      <Content>
        <Loading />
      </Content>
    )
  }
  return (
    <Content>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleFormikSubmit(validationSchema.cast(values))}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({ isValid }) => {
          return (
            <Form>
              {step !== 1 && (
                <IconButton onClick={() => setStep(1)}>
                  <ArrowBack />
                </IconButton>
              )}
              {step === 1 && <UploadImageStep task={task} onSubmit={submitImage} />}
              {step === 2 && (
                <StepContainer header="Reflection" disabled={!isValid}>
                  <TaskDetails task={task} />
                  <Field
                    component={TextField}
                    multiline
                    rows={15}
                    rowsMax={20}
                    name="volunteerFeedbackReflection"
                    placeholder="Please add reflection about your time volunteering today"
                    className={classes.input}
                    variant="outlined"
                  />
                  <Field
                    component={VolunteerRatingField}
                    name="volunteerFeedbackRating"
                    label="How was your volunteering experience today?"
                  />
                  <NextButton text="Finish Task" type="submit" disabled={!isValid || !volunteerImage} />
                </StepContainer>
              )}
            </Form>
          )
        }}
      </Formik>
    </Content>
  )
}
