const TaskStatus = {
    InReview: 'in-review',
    DetailsRequired: 'details-required',
    NotAccepted: 'not-accepted',
    Accepted: 'accepted',
    Scheduled: 'scheduled',
    Canceled: 'canceled',
    InProgress: 'in-progress',
    ClaimedCompleted: 'claimed-completed',
    Completed: 'completed',
    NotCompleted: 'not-completed',
}

export default TaskStatus
