import React, { useMemo, useState } from 'react'
import { Button } from '@material-ui/core'
import CenterContent from '../components/CenterContent'
import useOrganizations from '../hooks/useOrganizations'
import Loading from '../components/Loading'
import OrganizationCard from './OrganizationCard'
import { useHistory } from 'react-router'
import useGroupMemberships from '../hooks/useGroupMemberships'
import { REQUIRED_GROUP_TYPES } from '../api/utils/constants'
import * as _ from 'lodash'
import { MembershipStatusCode } from '../utils/membership-constants'

const useMembershipsByOrgId = (user) => {
  const { memberships, loading } = useGroupMemberships({
    groupType: REQUIRED_GROUP_TYPES.volunteer,
    personId: user.id,
  })

  const membershipsByOrgId = useMemo(() => {
    if (loading) {
      return {}
    }
    // no need to show a membership of 'removed'
    const filteredMemberships = _.filter(
      memberships,
      (membership) => membership.status.code !== MembershipStatusCode.Removed,
    )
    return _.keyBy(filteredMemberships, 'organization.id')
  }, [loading, memberships])
  return { membershipsByOrgId, loading }
}

// hide the org if they were banned or declined
const useFilteredOrgs = ({ membershipsByOrgId, organizations, loading }) =>
  useMemo(() => {
    if (loading) {
      return []
    }
    return organizations.filter((organization) => {
      const membership = membershipsByOrgId[organization.id]
      // perhaps we should show to declined, as the user could theoretically re-apply
      return (
        !membership || ![MembershipStatusCode.Declined, MembershipStatusCode.Banned].includes(membership.status.code)
      )
    })
  }, [membershipsByOrgId, organizations, loading])

function Organizations(props) {
  const { user, loading: userLoading } = props
  const [selected, setSelected] = useState(null)
  const { organizations, loading: orgsLoading } = useOrganizations()
  const { membershipsByOrgId, loading: membershipsLoading } = useMembershipsByOrgId(user)
  const loading = userLoading || orgsLoading || membershipsLoading
  const filteredOrgs = useFilteredOrgs({ membershipsByOrgId, organizations, loading })
  const history = useHistory()
  const onSelectOrg = (org) => (selected && selected.id === org.id ? setSelected(null) : setSelected(org))
  return (
    <CenterContent>
      <h1>Organization List</h1>
      {loading ? (
        <Loading />
      ) : (
        <>
          {filteredOrgs.map((org) => (
            <OrganizationCard
              key={org.id}
              org={org}
              onClick={onSelectOrg}
              isSelected={selected && selected.id === org.id}
              membership={membershipsByOrgId[org.id]}
            />
          ))}
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={() => history.push(`organization/${selected.id}`)}
            disabled={!selected}
          >
            Apply to Volunteer
          </Button>
        </>
      )}
    </CenterContent>
  )
}

export default Organizations
