import React, { useEffect } from 'react'
import usePost from 'civic-champs-shared/api/hooks/usePost'
import { useRouteMatch } from 'react-router'
import { useMount } from 'react-use'
import { OPT_OUT_TOKEN} from "../../config-amplify";
import { OptOutResponse, OptOutPayloadLessPersonId } from "./interface";
import useGetOnMount from "civic-champs-shared/api/hooks/useGetOnMount";


export default function OptOut() {
  const { params } = useRouteMatch()
  const { token } = params

  useMount(async () => {
    return token ? localStorage.setItem(OPT_OUT_TOKEN, token) : localStorage.removeItem(OPT_OUT_TOKEN)
  })

  // we want to remove the opt out token when we leave the page,
  // so that it is not included in other calls
  useEffect(() => {
    return () => localStorage.removeItem(OPT_OUT_TOKEN)
  })

  const [, { loading: fetchLoading }] = useGetOnMount<OptOutResponse>({
    errorMessage: 'Error fetching opt out preferences',
    emptyValue: {
      categories: [],
      nonOptOutAbleCategories: [],
      channels: [],
      templates: [],
      optOuts: [],
      organizations: [],
    },
    url: '/opt-out',
  })

  const [optOut, { loading: updateLoading }] = usePost<OptOutResponse, OptOutPayloadLessPersonId[]>({
    errorMessage: 'Error updating your opt out preferences',
    successMessage: 'Successfully updated your opt out preferences',
    configMapper: (body: OptOutPayloadLessPersonId[]) => ({ url: '/opt-out', config: { body } }),
  })

  return <div>Opt out preferences</div>
}
