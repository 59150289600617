import React from 'react'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import useRoutes from './hooks/useRoutes'
import { Redirect, Route, Switch } from 'react-router'
import Navbar from './components/Navbar'
import { makeStyles } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import ModalPromptProvider from 'civic-champs-shared/core/modal/ModalPromptProvider'
import { UserContextProvider, UserContext } from './auth/UserProvider'
import AuthInitializedGate from './auth/AuthInitializedGate'

const useStyles = makeStyles((theme) => ({
  content: {
    overflowX: 'auto',
    overflowY: 'auto',
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    position: 'relative',
    minHeight: 'calc(100vh - 48px)',
  },
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    minHeight: '100%',
    width: '100%',
  },
}))

function App() {
  const routes = useRoutes()
  const classes = useStyles()
  return (
    <SnackbarProvider>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ModalPromptProvider>
          <Navbar />
          <main className={classes.content}>
            <div className={classes.container}>
              <UserContextProvider>
                {/* TODO: try to clean this up */}
                <UserContext.Consumer>
                  {({ user, loggedIn, loading }) => (
                    <AuthInitializedGate user={user} loggedIn={loggedIn} loading={loading}>
                      <Switch>
                        {routes.map((route, index) => {
                          const { component: Component, path } = route
                          return (
                            <Route
                              path={path}
                              render={(props) =>
                                !loggedIn && !route.public ? (
                                  <Redirect to="/login" user={user} loggedIn={loggedIn} loading={loading} />
                                ) : (
                                  <Component user={user} loggedIn={loggedIn} loading={loading} {...props} />
                                )
                              }
                              key={index}
                            />
                          )
                        })}
                      </Switch>
                    </AuthInitializedGate>
                  )}
                </UserContext.Consumer>
              </UserContextProvider>
            </div>
          </main>
        </ModalPromptProvider>
      </MuiPickersUtilsProvider>
    </SnackbarProvider>
  )
}

export default App
