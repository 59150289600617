import { useFetch } from '../api/hooks'
import { useCallback, useEffect, useMemo } from 'react'

const STABLE_EMPTY_ARRAY = []

const usePersonLocations = (personId, onSuccess) => {
  const [request, { loading, called, error, result }] = useFetch('Error fetching locations')
  const getPersonLocations = useCallback(() => {
    return request({
      method: 'get',
      url: `/persons/${personId}/locations`,
      onSuccess,
    })
  }, [request, onSuccess, personId])

  useEffect(() => {
    getPersonLocations()
  }, [getPersonLocations])

  return useMemo(
    () => ({
      personLocations: result || STABLE_EMPTY_ARRAY,
      loading: loading || !called,
      called,
      error,
    }),
    [result, loading, called, error],
  )
}

export default usePersonLocations
