import * as React from 'react'
import { QuestionFormBaseProps, Question } from '../../../../types'
import { BaseSyntheticEvent } from 'react'
import { TextareaComponent } from '../../base/textarea'
import QuestionSkeleton from '../question-skeleton'

interface TextQuestionProps extends QuestionFormBaseProps {
  onChange: (value: string, item: Question) => void
  onBlur: () => void
}

const TextQuestion = (props: TextQuestionProps) => {
  const { item, onChange, error, onBlur } = props

  if (item.disabled) {
    return null
  }

  const handleChange = (item: Question) => (event: BaseSyntheticEvent) => onChange(event.target.value.trimLeft(), item)

  return (
    <QuestionSkeleton questionTitle={item.question} error={error} isRequired={item.isRequired}>
      <TextareaComponent value={item.response || ''} onChange={handleChange(item)} onBlur={onBlur} />
    </QuestionSkeleton>
  )
}

export default TextQuestion
