import React, { useState, useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepContent from '@material-ui/core/StepContent'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { taskIsAccepted, taskIsCompleted, taskIsInProgress, taskIsScheduled } from '../utils/taskUtils'
import TaskStatus from '../utils/TaskStatus'
import * as moment from 'moment-timezone'
import RecipientFeedback from './RecipientFeedback'
import { IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import * as _ from 'lodash'
import CancelModal from './CancelModal'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  header: {
    textAlign: 'center',
  },
  leftAlign: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
}))

const eventToDate = (date, timeZone, format) => moment(date).tz(timeZone).format(format)

const findEvent = (statusArr, taskHistory) =>
  taskHistory.find((event) => _.castArray(statusArr).includes(event.newTaskStatus))

const useGetStepContent = ({ task, taskHistory }) =>
  useMemo(() => {
    const volunteerName = task.volunteer ? `${task.volunteer.givenName} ${task.volunteer.familyName}` : ''
    const timeZone = task.organization.timeZone || 'America/New_York'
    const approvalEvent = findEvent(TaskStatus.Accepted, taskHistory)
    const assignedEvent = findEvent(TaskStatus.Scheduled, taskHistory)
    const startedEvent = findEvent(TaskStatus.InProgress, taskHistory)
    const completedEvent = findEvent([TaskStatus.Completed, TaskStatus.ClaimedCompleted], taskHistory)
    const feedbackEvent = taskHistory.find((history) => history.payload.recipientFeedbackReflection)
    return {
      0: approvalEvent
        ? `This task was approved on ${eventToDate(approvalEvent.occurredAt, timeZone, 'MM/DD/YY')}`
        : '',
      1: assignedEvent
        ? `${volunteerName} ${eventToDate(task.startTime, timeZone, 'MM/DD/YY')} ${eventToDate(
            task.startTime,
            timeZone,
            'hh:mm a',
          )} - ${eventToDate(task.endTime, timeZone, 'hh:mm a')}
      `
        : '',
      2: startedEvent
        ? `${volunteerName} started the task at ${eventToDate(startedEvent.occurredAt, timeZone, 'hh:mm a')}`
        : '',
      3: completedEvent
        ? `${volunteerName} completed on ${eventToDate(completedEvent.occurredAt, timeZone, 'hh:mm a')}`
        : '',
      4: feedbackEvent
        ? `${feedbackEvent.actor.givenName} ${feedbackEvent.actor.familyName} provided feedback on ${eventToDate(
            feedbackEvent.occurredAt,
            timeZone,
            'hh:mm a',
          )}`
        : '',
    }
  }, [task, taskHistory])

const steps = {
  FEEDBACK: 'feedback',
  COMPLETE: 'complete',
  IN_PROGRESS: 'inProgress',
  SCHEDULED: 'scheduled',
  ACCEPTED: 'accepted',
  IN_REVIEW: 'in_review',
}

const stepToIndex = {
  [steps.FEEDBACK]: 5,
  [steps.COMPLETE]: 4,
  [steps.IN_PROGRESS]: 3,
  [steps.SCHEDULED]: 2,
  [steps.ACCEPTED]: 1,
  [steps.IN_REVIEW]: 0,
}

const useGetActiveStep = (task) =>
  useMemo(() => {
    if (task.recipientFeedback) {
      return steps.FEEDBACK
    } else if (taskIsCompleted(task)) {
      return steps.COMPLETE
    } else if (taskIsInProgress(task)) {
      return steps.IN_PROGRESS
    } else if (taskIsScheduled(task)) {
      return steps.SCHEDULED
    } else if (taskIsAccepted(task)) {
      return steps.ACCEPTED
    } else {
      return steps.IN_REVIEW
    }
  }, [task])

const useStepLabels = (activeStep) =>
  useMemo(() => {
    switch (activeStep) {
      case steps.IN_REVIEW:
        return ['In Review', 'Assigned', 'Started', 'Completed', 'Feedback']
      case steps.ACCEPTED:
        return ['Approved', 'Assigning', 'Started', 'Completed', 'Feedback']
      case steps.SCHEDULED:
        return ['Approved', 'Assigned', 'Starting', 'Completed', 'Feedback']
      case steps.IN_PROGRESS:
        return ['Approved', 'Assigned', 'Started', 'In Progress', 'Feedback']
      case steps.COMPLETE:
        return ['Approved', 'Assigned', 'Started', 'Completed', 'Feedback']
      case steps.FEEDBACK:
        return ['Approved', 'Assigned', 'Started', 'Completed', 'Feedback']
      default:
        return ['Approved', 'Assigned', 'Started', 'Completed', 'Feedback']
    }
  }, [activeStep])

const StepButton = ({ activeStep, classes, onProvideFeedback, onCancel }) => {
  const { label, onClick } = useMemo(() => {
    if ([steps.SCHEDULED, steps.ACCEPTED, steps.IN_REVIEW].includes(activeStep)) {
      return { label: 'Cancel Task', onClick: onCancel }
    } else if (activeStep === steps.COMPLETE) {
      return { label: 'Provide Feedback', onClick: onProvideFeedback }
    }
    return {}
  }, [activeStep, onProvideFeedback, onCancel])

  if (label && onClick) {
    return (
      <Button variant="contained" color="primary" onClick={onClick} className={classes.button}>
        {label}
      </Button>
    )
  }
  return null
}

export default function RequesterTaskDetails(props) {
  const { task, taskHistory, disabled } = props
  const [showFeedback, setShowFeedback] = useState(false)
  const [showCancel, setShowCancel] = useState(false)
  const content = useGetStepContent({ task, taskHistory })
  const classes = useStyles()
  const history = useHistory()
  const activeStep = useGetActiveStep(task)
  const labels = useStepLabels(activeStep)
  if (showFeedback) {
    return (
      <>
        <IconButton className={classes.leftAlign} onClick={() => setShowFeedback(false)}>
          <ArrowBack />
        </IconButton>
        <RecipientFeedback task={task} />
      </>
    )
  }
  return (
    <div className={classes.root}>
      <h1 className={classes.header}>Task Info</h1>
      <Stepper activeStep={stepToIndex[activeStep]} orientation="vertical">
        {labels.map((label, index) => (
          <Step key={label}>
            <StepLabel>
              <Typography variant="h6">{label}</Typography>
              <Typography variant="subtitle1">{content[index]}</Typography>
            </StepLabel>
            <StepContent>
              <div className={classes.actionsContainer}>
                {!disabled && (
                  <StepButton
                    activeStep={activeStep}
                    classes={classes}
                    onProvideFeedback={() => setShowFeedback(true)}
                    onCancel={() => setShowCancel(true)}
                  />
                )}
              </div>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <CancelModal
        open={showCancel}
        onClose={() => setShowCancel(false)}
        task={task}
        complete={() => history.push('/receive-dashboard')}
      />
    </div>
  )
}
