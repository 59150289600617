import React from 'react'
import * as moment from 'moment-timezone'
import { makeStyles, Box } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert';

import Loading from './Loading'
import useCustomMessage from '../hooks/useCustomMessage'
import DOMPurify from 'dompurify';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    paddingTop: 20,
    width: '90%',

    [theme.breakpoints.up('md')]: {
      width: '40%',
    },
  },
  errorMessage: {
    transform: 'translateY(-100%)',
  },
  link: {
    textDecoration: 'none',
  },
  title: {
    marginBottom: 12,
    marginTop: 16,
    fontSize: 14,
    textAlign: 'left',
    wordBreak: 'break-word',
  },
}))

export default function Message(props) {
  const { uuid, recipientId } = props.match.params
  const classes = useStyles()
  const { message, loading } = useCustomMessage(uuid, recipientId)

  if (loading) {
    return <Loading />
  }

  if (!message) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        height="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Box className={classes.errorMessage}>
          <Alert severity="error">No message exists with the id {uuid}</Alert>
        </Box>
      </Box>
    )
  }

  const { subject, userEnteredSubject, body, organization, createdAt } = message

  return (
    <div className={classes.container}>
      <div className={classes.title}>
        <b>{moment(createdAt).tz(organization.timeZone).format('MMMM Do, YYYY')}</b>
      </div>

      <div className={classes.title}>
        <b>From: </b>
        <span>{organization.name}</span>
      </div>

      <div className={classes.title}>
        <b>Subject: </b>
        <span>{userEnteredSubject || subject}</span>
      </div>

      {body && (
        <>
          <div className={classes.title}>
            <Box fontWeight="bold" mb={1}>Message: </Box>
            <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(body)}}></div>
          </div>
        </>
      )}
    </div>
  )
}
