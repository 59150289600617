import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import AuthReducer from '../auth/authReducer'
import recipientReducer from '../request/redux/recipientReducer'

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    auth: AuthReducer,
    recipient: recipientReducer,
  })

export default createRootReducer
