// other imports
import Loading from '../../components/Loading'
import { defaultLocation } from 'civic-champs-shared/core/location/utils'
import Content from '../../components/Content'
import * as _ from 'lodash'
import { IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import moment from 'moment'
import RequirementsModal from 'civic-champs-shared/question-sets/components/RequirementsModal'
import { REQUIRED_GROUP_TYPES } from '../../api/utils/constants'
import { PrerequisitesAction } from 'civic-champs-shared/question-sets/types'

// steps
import ChooseRecipient from './Steps/ChooseRecipient'
import ChooseContact from './Steps/ChooseContact'
import ChooseLocation from './Steps/ChooseLocation'
import ChooseOrganization from './Steps/ChooseOrganization'
import ChooseServiceType from './Steps/ChooseServiceType'
import TaskDescription from './Steps/TaskDescription'
import ChooseTimeSlots from './Steps/ChooseTimeSlots'
import ConfirmTask from './Steps/ConfirmTask'

// hooks
import useCreateTask from '../../hooks/useCreateTask'
import { useHistory } from 'react-router'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'

const initialValues = {
  recipient: null,
  contact: null,
  organization: null,
  location: _.cloneDeep(defaultLocation),
  serviceType: null,
  description: '',
  timeslots: [],
}

export default function RequestTask(props) {
  const { user: signedInPerson, loading: personLoading } = props
  const [step, setStep] = useState(1)
  const { createTask, loading } = useCreateTask()
  const history = useHistory()
  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)

  const nextStep = () => {
    setStep(step + 1)
  }
  const prevStep = () => {
    setStep(step - 1)
  }

  const handleRequirements = async (organizationId) => {
    try {
      return await fulfillRequirementsPrompt({
        filters: {
          associatedEntity: { requiredGroup: REQUIRED_GROUP_TYPES.requester, organizationId },
          action: PrerequisitesAction.GROUP_SIGN_UP,
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
        },
        continueEvenIfError: false,
      })
    } catch (error) {
      return false
    }
  }

  const handleFormikSubmit = async (values) => {
    const payload = {
      // NOTE: API should be organizationId !
      organization: values.organization.id,
      contactId: values.contact.id,
      recipientId: values.recipient.id,
      volunteeringRoleId: values.serviceType.id,
      locationId: values.location.id,
      overdueAt: moment.max(values.timeslots.map((ts) => moment(ts.endTime))).format(),
      timeslots: values.timeslots,
      description: values.description,
    }
    await createTask(payload)
    history.push('/receive-or-lend')
  }

  if (loading || personLoading) {
    return (
      <Content>
        <Loading />
      </Content>
    )
  }
  return (
    <Content>
      <Formik initialValues={initialValues} onSubmit={handleFormikSubmit} noValidation>
        {({ values, setFieldValue, dirty, isSubmitting }) => {
          // should be able to use formik handleChange, but
          const onChange = (key) => (val) => setFieldValue(key, val)
          const orgNextStep = async () => {
            if (await handleRequirements(values.organization.id)) {
              nextStep()
            }
          }
          return (
            <Form>
              {step !== 1 && (
                <IconButton onClick={prevStep}>
                  <ArrowBack />
                </IconButton>
              )}
              {step === 1 && (
                <ChooseRecipient
                  nextStep={nextStep}
                  value={values.recipient}
                  onChange={onChange('recipient')}
                  signedInPerson={signedInPerson}
                />
              )}
              {step === 2 && (
                <ChooseContact
                  nextStep={nextStep}
                  value={values.contact}
                  onChange={onChange('contact')}
                  recipient={values.recipient}
                  signedInPerson={signedInPerson}
                />
              )}
              {step === 3 && (
                <ChooseLocation
                  nextStep={nextStep}
                  value={values.location}
                  onChange={onChange('location')}
                  recipient={values.recipient}
                  signedInPerson={signedInPerson}
                />
              )}
              {step === 4 && (
                <ChooseOrganization
                  nextStep={orgNextStep}
                  value={values.organization}
                  onChange={onChange('organization')}
                  longitude={_.get(values, 'location.geofencing.position.coordinates.1')}
                  latitude={_.get(values, 'location.geofencing.position.coordinates.0')}
                />
              )}
              {step === 5 && (
                <ChooseServiceType
                  nextStep={nextStep}
                  value={values.serviceType}
                  onChange={onChange('serviceType')}
                  organization={values.organization}
                />
              )}
              {step === 6 && <TaskDescription nextStep={nextStep} value={values.description} />}
              {step === 7 && <ChooseTimeSlots nextStep={nextStep} value={values.timeslots} />}
              {step === 8 && (
                // values get wiped away after submission, so call it loading if back to untouched state (i.e. !dirty)
                <ConfirmTask
                  values={values}
                  recipient={values.recipient}
                  serviceType={values.serviceType}
                  loading={loading || !dirty || isSubmitting}
                />
              )}
            </Form>
          )
        }}
      </Formik>
    </Content>
  )
}
