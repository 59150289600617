import * as React from 'react'
import { Formik, FormikErrors, FormikProps } from 'formik'
import { Question, QuestionType, ConditionLookup, QuestionAnsweringFormProps } from '../../types'
import { initQuestionAnswers, mapQuestionToAnswerPayload } from '../../utils'
import './index.scss'
import * as yup from 'yup'
import { answerSetValidationSchema } from '../../schema'
import { Button } from '@material-ui/core'
import { RadioQuestion, CheckboxQuestion, SelectQuestion, InputQuestion, TextQuestion } from './questions'
import TrueFalseQuestion from './questions/true-false-question'
import { InitialQuestionAnsweringValues } from './interface'

export const QuestionAnsweringForm = (props: QuestionAnsweringFormProps) => {
  const {
    onSubmit,
    onSkip,
    questionSet,
    children,
    additionalInitialValues = {},
    additionalSchema = yup.object().shape({}),
    required,
    heading,
  } = props

  const validationSchema = additionalSchema.concat(yup.object({ questionSet: answerSetValidationSchema }))

  const submit = (values: any) => {
    const payload = {
      ...additionalInitialValues,
      ...values,
      questionSet: { ...questionSet, questions: values.questionSet.questions.map(mapQuestionToAnswerPayload) },
    }
    onSubmit(payload)
  }

  const initialValues: InitialQuestionAnsweringValues = {
    ...additionalInitialValues,
    questionSet: { ...questionSet, questions: initQuestionAnswers(questionSet) },
  }

  return (
    <div style={{ width: '95%', overflow: 'auto' }}>
      {heading && (
        <h2>
          <b>{heading}</b>
        </h2>
      )}
      <div className="feedback-wrap">
        <Formik validationSchema={validationSchema} initialValues={initialValues} onSubmit={submit}>
          {(props: FormikProps<InitialQuestionAnsweringValues>) => {
            const { values, errors, touched, handleSubmit, isSubmitting, setFieldTouched, setFieldValue } = props
            const getFormikQuestionErrors = (index: number) => {
              return (errors &&
                errors.questionSet &&
                errors.questionSet.questions &&
                errors.questionSet.questions[index]) as FormikErrors<Question>
            }

            const getFormikQuestionsTouched = (index: number) => {
              return (
                touched && touched.questionSet && touched.questionSet.questions && touched.questionSet.questions[index]
              )
            }

            const renderErrors = (index: number) => {
              return getFormikQuestionsTouched(index) && getFormikQuestionErrors(index)
            }

            const strategyToRenderQuestion = (currentQuestion: Question, index: number): any => ({
              [QuestionType.SINGLE_CHOICE]: (
                <RadioQuestion
                  key={index}
                  error={renderErrors(index)}
                  item={currentQuestion}
                  onChange={handleChangeValue(index, 'response')}
                  onBlur={handleBlurValue(index)}
                />
              ),
              [QuestionType.MULTIPLE_CHOICE]: (
                <CheckboxQuestion
                  key={index}
                  error={renderErrors(index)}
                  item={currentQuestion}
                  onChangeCheckbox={handleChangeValue(index, 'response')}
                  onChangeOtherAnswer={handleChangeValue(index, 'otherAnswer')}
                  onBlur={handleBlurValue(index)}
                />
              ),
              [QuestionType.DROPDOWN]: (
                <SelectQuestion
                  key={index}
                  error={renderErrors(index)}
                  item={currentQuestion}
                  onChange={handleChangeValue(index, 'response')}
                  onBlur={handleBlurValue(index)}
                />
              ),
              [QuestionType.SHORT_TEXT]: (
                <InputQuestion
                  key={index}
                  error={renderErrors(index)}
                  item={currentQuestion}
                  onChange={handleChangeValue(index, 'response')}
                  onBlur={handleBlurValue(index)}
                />
              ),
              [QuestionType.NUMBER]: (
                <InputQuestion
                  key={index}
                  error={renderErrors(index)}
                  item={currentQuestion}
                  onChange={handleChangeValue(index, 'response')}
                  onBlur={handleBlurValue(index)}
                />
              ),
              [QuestionType.LONG_TEXT]: (
                <TextQuestion
                  key={index}
                  error={renderErrors(index)}
                  item={currentQuestion}
                  onChange={handleChangeValue(index, 'response')}
                  onBlur={handleBlurValue(index)}
                />
              ),
              [QuestionType.TRUE_OR_FALSE]: (
                <TrueFalseQuestion
                  error={renderErrors(index)}
                  item={currentQuestion}
                  onChange={handleChangeValue(index, 'response')}
                  onBlur={handleBlurValue(index)}
                />
              ),
            })

            const questionMapper = (allQuestions: Question[], currentQuestion: Question, index: number) => {
              return currentQuestion.questionType
                ? strategyToRenderQuestion(currentQuestion, index)[currentQuestion.questionType as QuestionType]
                : null
            }

            const triggerAnswerByQuestionId: ConditionLookup = values.questionSet.questions.reduce(
              (acc: ConditionLookup, question: Question, targetedQuestionIndex: number) => {
                const { conditionedOnAnswer, conditionedOnQuestionId, questionId: targetedQuestionId } = question
                if (conditionedOnQuestionId) {
                  acc[conditionedOnQuestionId] = [
                    ...(acc[conditionedOnQuestionId] || []),
                    { conditionedOnAnswer, targetedQuestionId, targetedQuestionIndex },
                  ]
                }
                return acc
              },
              {},
            )

            const handleChangeValue = (idx: number, key: keyof Question) => (value: any) => {
              if (key === 'response') {
                const { questionId } = values.questionSet.questions[idx]
                const triggerInfos = triggerAnswerByQuestionId[questionId]
                if (triggerInfos) {
                  triggerInfos.forEach(triggerInfo => {
                    const { conditionedOnAnswer, targetedQuestionIndex } = triggerInfo
                    setFieldValue(
                      `questionSet.questions[${targetedQuestionIndex}].disabled`,
                      conditionedOnAnswer !== value,
                    )
                  })
                }
              }
              setFieldValue(`questionSet.questions[${idx}][${key}]`, value)
            }

            const handleBlurValue = (idx: number) => () => {
              setFieldTouched(`questionSet.questions[${idx}]`, true)
            }
            return (
              <form onSubmit={handleSubmit}>
                <div className="feedback-form-block">
                  <div className="feedback-form-block-question-list">
                    {children}
                    {values.questionSet.questions.map((question: Question, index: number) =>
                      questionMapper(values.questionSet.questions, question, index),
                    )}
                  </div>
                  <div className="feedback-form-block__submit">
                    {!required && onSkip && <Button onClick={() => onSkip(values)}>SKIP</Button>}
                    <Button type="submit" disabled={isSubmitting}>
                      SUBMIT
                    </Button>
                  </div>
                </div>
              </form>
            )
          }}
        </Formik>
      </div>
    </div>
  )
}
