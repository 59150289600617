import React from 'react'
import CircleCheckedFilled from '@material-ui/icons/CheckCircle'
import CircleUnchecked from '@material-ui/icons/RadioButtonUnchecked'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Field } from 'formik'

export default function RadioSelect(props) {
  const {
    options,
    value,
    onChange,
    isChecked = (option, value) => String(option.id) === String(value && value.id),
    getLabel = (option) => option.name,
  } = props

  return (
    <Field component={RadioGroup}>
      {options.map((option, index) => {
        const label = getLabel(option)
        return (
          <FormControlLabel
            key={`${index}-${label}`}
            checked={isChecked(option, value)}
            onChange={() => onChange(option)}
            control={<Radio icon={<CircleUnchecked />} checkedIcon={<CircleCheckedFilled />} color="primary" />}
            label={label}
          />
        )
      })}
    </Field>
  )
}
