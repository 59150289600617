import { useFetch } from '../api/hooks'
import { useCallback, useEffect } from 'react'

const STABLE_EMPTY_ARRAY = []

const useTaskVolunteeringRoles = (orgId) => {
  const [request, { result, loading, called }] = useFetch('Error fetching service types')

  const getTaskRoles = useCallback(() => {
    return request({
      method: 'get',
      url: `/organizations/${orgId}/task_roles`,
    })
  }, [orgId, request])

  useEffect(() => {
    getTaskRoles()
  }, [getTaskRoles])

  return {
    roles: result || STABLE_EMPTY_ARRAY,
    loading: loading || !called,
    refresh: getTaskRoles,
  }
}

export default useTaskVolunteeringRoles
