import { useCallback } from 'react'

import { useFetch, useSuccessNotification } from '../api/hooks'

const useCreateTask = () => {
  const [request, { loading }] = useFetch('Error creating the task')
  const showSuccess = useSuccessNotification()
  const createTask = useCallback(
    (task) => {
      return request({
        method: 'post',
        url: `/organizations/${task.organization}/tasks`,
        onSuccess: () => showSuccess('Successfully created the task'),
        config: {
          body: task,
        },
      })
    },
    [request, showSuccess],
  )

  return { createTask, loading }
}

export default useCreateTask
