import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { API } from 'aws-amplify'
import { useErrorNotification, useSuccessNotification } from './index'
import { FetchProps, Request, Status } from './fetchRefactoredSchema'
import * as _ from 'lodash'

export default function useFetchRefactored<T>(props: FetchProps<T> = {}): [Request<T>, Status<T>] {
  const { errorMessage, successMessage, emptyValue = null, isPublicRoute } = props
  const [loading, setLoading] = useState<boolean>(false)
  const [called, setCalled] = useState<boolean>(false)
  const [result, setResult] = useState<T | null>(_.cloneDeep(emptyValue))
  const [error, setError] = useState<Error | null>(null)
  const showError = useErrorNotification()
  const showSuccess = useSuccessNotification()
  const request: Request<T> = useCallback(
    async (requestConfig) => {
      const { method = 'get', url, config, onSuccess, onError } = requestConfig
      try {
        setCalled(true)
        setLoading(true)
        setError(null)
        // @ts-ignore
        let result: T = await API[method](isPublicRoute ? 'civicChampsPublicApi' : 'civicChampsApi', url, config)
        setResult(result)
        if (onSuccess) {
          onSuccess(result)
        }
        if (successMessage) {
          showSuccess(successMessage)
        }
        return result
      } catch (error) {
        setError(error)
        if (onError) {
          onError(error)
        }
        if (errorMessage) {
          showError(errorMessage, error)
        }
        throw error
      } finally {
        setLoading(false)
      }
    },
    [setCalled, setLoading, setResult, setError, showSuccess, showError, errorMessage, successMessage],
  )

  // @ts-ignore
  const status: Status<T> = useMemo(() => ({ loading, called, result, error }), [loading, called, result, error])

  return [request, status]
}
