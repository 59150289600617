import { useCallback } from 'react'
import { useFetch } from '../api/hooks'
import useSuccessNotification from 'civic-champs-shared/api/hooks/useSuccessNotification'

const useSaveAvailability = () => {
  const [request, { loading }] = useFetch('Error saving volunteer availability')
  const showSuccess = useSuccessNotification()
  const saveAvailability = useCallback(
    async (volunteerAvailability) => {
      return request({
        method: 'put',
        url: `/volunteer-availability`,
        config: {
          body: volunteerAvailability,
        },
        onSuccess: () => showSuccess('Your availability has been saved!'),
      })
    },
    [request, showSuccess],
  )

  return {
    saveAvailability,
    loading,
  }
}

export default useSaveAvailability
