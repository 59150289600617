import React from 'react'
import formatPersonName from '../utils/formatPersonName'
import formatTimeslot from '../utils/formatTimeslot'
import { Directions } from '@material-ui/icons'
import { makeStyles } from '@material-ui/core'
import browserIsSafari from '../utils/browserIsSafari'

const useStyles = makeStyles((theme = {}) => ({
  directions: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    fontSize: '1.2rem',
  },
}))

const TaskDetails = ({ task, hideTaskTime }) => {
  const classes = useStyles()
  const {
    location: { address },
  } = task
  const mapUrl = browserIsSafari() ? 'http://maps.apple.com' : 'http://maps.google.com/'
  const mapsLink = `${mapUrl}?q=${encodeURIComponent(address.line1)}+${encodeURIComponent(
    address.city,
  )}+${encodeURIComponent(address.state)}}`
  return (
    <div style={{ textAlign: 'center' }}>
      <h2>{task.volunteeringRole.name}</h2>
      <p>for</p>
      <h3>{formatPersonName(task.recipient)}</h3>
      {task.startTime && task.endTime && !hideTaskTime && (
        <>
          <h3>Task Timing</h3>
          <p>{formatTimeslot(task.startTime, task.endTime)}</p>
        </>
      )}
      <a target="_blank" rel="noopener noreferrer" href={mapsLink} className={classes.directions}>
        <Directions />
        Directions
      </a>
    </div>
  )
}

export default TaskDetails
