import React from 'react'
import { AppBar, Button, IconButton, makeStyles } from '@material-ui/core'
import { Auth } from 'aws-amplify'
import { Link, useHistory } from 'react-router-dom'
import { Home } from '@material-ui/icons'
import useUserStatus from '../auth/useUserStatus'

const useStyles = makeStyles((theme) => ({
  bar: {
    display: 'flex',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
  menuIcon: {
    color: theme.palette.neutral.white,
    marginLeft: theme.spacing(2),
    display: 'flex-item',
  },
  logoutButton: {
    color: theme.palette.neutral.white,
    marginRight: theme.spacing(2),
  },
}))

export default function Navbar() {
  const classes = useStyles()
  const { loggedIn } = useUserStatus()
  const history = useHistory()

  const handleLoginClick = () => {
    history.push('/login')
  }

  const handleLogoutClick = async () => {
    await Auth.signOut()
    history.push('/login')
  }

  return (
    <AppBar position="static" color="secondary" className={classes.bar}>
      <Link to="/receive-or-lend">
        <IconButton edge="start" aria-label="menu" className={classes.menuIcon}>
          <Home />
        </IconButton>
      </Link>
      {loggedIn ? (
        <Button onClick={handleLogoutClick} variant="text" className={classes.logoutButton}>
          Logout
        </Button>
      ) : (
        <Button onClick={handleLoginClick} variant="text" className={classes.logoutButton}>
          Login
        </Button>
      )}
    </AppBar>
  )
}
