import React, { useState } from 'react'
import { useRequirementsManagement } from '../hooks/useRequirementsManagement'
import { QuestionAnsweringForm } from './question-set-answering/QuestionAnsweringForm'
import Loading from '../../core/Loading'
import { RequirementsProps } from '../types'
import * as _ from 'lodash'
import Waiver from './Waiver'

export const RequirementsContainer = (props: RequirementsProps) => {
  const { isAdmin, continueEvenIfError } = props
  const [excludedQuestionSetIds, setExcludedQuestionSetIds] = useState<Array<number>>([])
  const { loading, requirements, allSet, refresh, answerQuestionSet, person } = useRequirementsManagement({
    excludedQuestionSetIds,
    person: props.person,
    filters: props.filters,
    onCompleted: props.onCompleted,
    continueEvenIfError,
  })
  const { credentials, questionSets } = requirements
  const onSubmit = (submission: any) => answerQuestionSet(submission.questionSet)

  if (loading) {
    return <Loading />
  }

  if (allSet) {
    return <div>All Set!</div>
  }

  if (credentials.length) {
    return <Waiver waivers={credentials} user={person} complete={refresh} />
  }

  if (questionSets.length) {
    const [questionSet] = questionSets
    return (
      <QuestionAnsweringForm
        onSubmit={onSubmit}
        heading={`Please answer the following questions in order to register for '${questionSet.associatedEntity.name}'`}
        questionSet={{...questionSet}}
        required={isAdmin || questionSet.questions.every(question => !question.isRequired || !!question.disabled) ? false : questionSet.required}
        onSkip={submission =>
          setExcludedQuestionSetIds(_.uniq([...excludedQuestionSetIds, submission.questionSet.questionSetId]))
        }
      />
    )
  }

  return <div>Uh oh! something went wrong</div>
}
