import React, { useState, useCallback, useMemo } from 'react';

import { ModalContextProvider } from './ModalContext';
import ModalPrompt from './ModalPrompt';

export default function ModalPromptProvider({ children }) {
    let [modals, setModals] = useState([]);

    let createPrompt = useCallback( (component) => {
        let prompt = new ModalPrompt(component);

        let addToList = () => setModals( prev => [...prev, prompt] );
        let removeFromList = () => setModals( prev => prev.filter( p => p !== prompt) );

        prompt.onShow(addToList);
        prompt.onHide(removeFromList);
        
        return prompt;
    }, [setModals]);

    let context = useMemo( () => ({ modals, createPrompt }), [modals, createPrompt]);

    return (
        <ModalContextProvider value={context}>
            {children}
            {modals.map( modal => modal.render() )}
        </ModalContextProvider>
    )
}