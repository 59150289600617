import * as yup from 'yup'
import { Question, QuestionSetResponse, QuestionType } from "./types";
import { OTHER_OPTION } from './utils'

const REQUIRED_FIELD = 'Required field'

const answerWithCheckboxValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.array().ensure().of(yup.string()).min(1, 'Please choose at least one option').required(REQUIRED_FIELD),
  otherwise: yup.array().ensure().of(yup.string()).nullable().notRequired()
})

const answerStringValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.string().trim().required(REQUIRED_FIELD),
  otherwise: yup.string().trim().nullable().notRequired()
})

const answerNumberValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.number().min(0).required(REQUIRED_FIELD),
  otherwise: yup.number().nullable().notRequired()
})

const answerTrueFalseValidationSchema = yup.mixed().when('isRequired', {
  is: true,
  then: yup.boolean().required(REQUIRED_FIELD),
  otherwise: yup.boolean().nullable().notRequired()
})

// NOTE: I can't see to use when('questionType'..., which would be ideal
// is it just casts everything to strings if I do that
const answerSchema = yup.lazy(value => {
  if (Array.isArray(value)) {
    return answerWithCheckboxValidationSchema
  } else if (typeof value === 'number') {
    return answerNumberValidationSchema
  } else if (typeof value === 'boolean') {
    return answerTrueFalseValidationSchema
  }
  return answerStringValidationSchema
})


const responseValidationSchema = yup.mixed().when('disabled', {
  is: true,
  then: yup.mixed().nullable(true).notRequired(),
  otherwise: answerSchema
})

const otherAnswerValidationSchema = yup.mixed().when(['disabled', 'response'], {
  is: (disabled: boolean, response: any) => !disabled && Array.isArray(response) && response.includes(OTHER_OPTION),
  then: yup.string().trim().required("Required field when 'Other' is selected"),
  otherwise: yup.string().trim().nullable(true).notRequired()
})

export const questionValidationSchema = yup.object().shape({
  questionId: yup.number(),
  answerId: yup.number().notRequired(),
  question: yup.string(),
  options: yup.array(),
  questionType: yup.string(),
  isRequired: yup.boolean(),
  otherAnswer: otherAnswerValidationSchema
})

export const answerValidationSchema = yup.object().shape({
  response: responseValidationSchema,
  disabled: yup.boolean().default(false),
}).concat(questionValidationSchema)

// @ts-ignore
export const answerSetValidationSchema = yup.object<QuestionSetResponse>().shape({
  questionSetId: yup.number().nullable().notRequired(),
  answerSetId: yup.number().nullable().notRequired(),
  organizationId: yup.number().nullable().notRequired(),
  name: yup.string().nullable().notRequired(),
  questions: yup.array<Question>().of(answerValidationSchema)
})
