import { useCallback } from "react"

import { useFetch} from "../../api/hooks";

export const useSignWaiver = () => {
    const [request, status] = useFetch('Error signing waiver.  Please refresh the page')

    const signWaiver = useCallback( async (personId, credentialId, associatedEntity) => {
        return request({
            method: 'post',
            url: '/credentials/sign',
            config: {
                body: {
                    personId,
                    credentialId,
                    associatedEntity
                }
            }
        })
    }, [request])

    return [signWaiver, status]
}
