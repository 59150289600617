import { useCallback } from 'react'
import { useFetch } from '../api/hooks'
import { REQUIRED_GROUP_TYPES } from '../api/utils/constants'

const useJoinOrgAsVolunteer = () => {
  const [request, { loading }] = useFetch('Error signing up')

  const joinOrgAsVolunteer = useCallback(
    (orgId) => {
      return request({
        method: 'post',
        url: `/organization/${orgId}/required-group/${REQUIRED_GROUP_TYPES.volunteer}/sign-up`,
      })
    },
    [request],
  )

  return { joinOrgAsVolunteer, loading }
}

export default useJoinOrgAsVolunteer
