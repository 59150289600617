import React, { useState } from 'react'
import StepContainer from './StepContainer'
import RadioSelect from '../../../components/RadioSelect'
import { TextField } from '@material-ui/core'

const OTHER = 'Other'
const options = ['Parent', 'Child', 'Sibling', 'Friend', 'Staff', OTHER]

export default function ChooseRelationship(props) {
  const { onSubmit, loading } = props
  const [relationship, setRelationship] = useState('')
  const [relationshipOther, setRelationshipOther] = useState('')
  const disabled = loading || !relationship || (relationship === OTHER && !relationshipOther)
  const otherSelected = relationship === OTHER
  const submit = () => {
    onSubmit(otherSelected ? relationshipOther : relationship)
  }

  return (
    <StepContainer
      header="Relationship"
      subHeader="What is your relationship with the recipient?"
      disabled={disabled}
      nextStep={submit}
    >
      <RadioSelect
        options={options}
        value={relationship}
        isChecked={(option, value) => option === value}
        onChange={setRelationship}
        getLabel={(option) => option}
      />
      {otherSelected && (
        <TextField
          value={relationshipOther}
          onChange={(e) => setRelationshipOther(e.target.value)}
          label="Enter relationship"
          placeholder="Enter relationship"
          variant="outlined"
        />
      )}
    </StepContainer>
  )
}
