import React, { useState } from 'react'
import { FormControl, OutlinedInput, InputAdornment, IconButton, FormHelperText } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import { fieldToTextField } from 'formik-material-ui'

function PasswordField(props) {
  const { field, form } = props
  const { touched } = form
  const { value, onChange, name } = field
  const [showPassword, setShowPassword] = useState(false)
  const errorMessage = (!props.hideErrorUntilTouched || touched[name]) && form.errors[name]
  const error = !!errorMessage
  const handleClick = () => {
    setShowPassword((value) => !value)
  }
  const handleMouseDown = (e) => {
    e.preventDefault()
  }
  // there's a warning in the console if you pass helperText, as it's not a valid input prop
  const { helperText, hideErrorUntilTouched, className, ...rest } = fieldToTextField(props)
  return (
    <FormControl error={error} variant="outlined" className={className}>
      <OutlinedInput
        id={name}
        {...rest}
        type={showPassword ? 'text' : 'password'}
        value={value}
        onChange={onChange}
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClick} onMouseDown={handleMouseDown} edge="end">
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          </InputAdornment>
        }
      />
      <FormHelperText error={error}>{errorMessage}</FormHelperText>
    </FormControl>
  )
}

export default PasswordField
