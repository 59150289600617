import { useFetch } from '../api/hooks'
import { useCallback, useEffect } from 'react'

const STABLE_ARRAY = []

const useAssignedTasks = () => {
  const [request, { loading, called, result }] = useFetch('Error fetching assigned tasks')

  const getTasks = useCallback(async () => {
    return request({
      method: 'get',
      url: `/user/assigned-tasks`,
    })
  }, [request])

  useEffect(() => {
    getTasks()
  }, [getTasks])

  return {
    assignedTasks: result || STABLE_ARRAY,
    loading: loading || !called,
    refresh: getTasks,
  }
}

export default useAssignedTasks
