import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import CheckboxField from '../../../components/CheckboxField'
// import BirthdayField from '../../../components/BirthdayField'
import { stepTwoSchema } from './schemas'
import { FormikEffect } from 'civic-champs-shared/question-sets/components'
import PhoneField from '../../../components/PhoneField'

function StepTwo(props) {
  const { onSubmit, classes, profile, onBack, onChange, emailIsPrimary } = props
  return (
    <Formik
      initialValues={{
        email: profile.email,
        phoneNumber: profile.phoneNumber,
        givenName: profile.givenName,
        familyName: profile.familyName,
        birthday: profile.birthday,
        olderThanThirteen: profile.olderThanThirteen,
      }}
      validationSchema={stepTwoSchema(emailIsPrimary)}
      onSubmit={(values) => onSubmit(stepTwoSchema(emailIsPrimary).cast(values))}
      validateOnBlur
    >
      {({ isValid, values, errors }) => (
        <Form>
          <FormikEffect
            onChange={(state) => {
              onChange(state.values)
            }}
            formik={{ values, errors }}
          />
          <Grid container spacing={2} direction="column" justify="center" alignItems="center">
            <div className={classes.title}>
              <b>First*</b>
            </div>
            <Field
              component={TextField}
              name="givenName"
              placeholder="First Name"
              className={classes.input}
              variant="outlined"
              autoCapitalize="on"
            />
            <div className={classes.title}>
              <b>Last*</b>
            </div>
            <Field
              component={TextField}
              name="familyName"
              placeholder="Last Name"
              className={classes.input}
              variant="outlined"
              autoCapitalize="on"
            />
            {!emailIsPrimary && (
              <>
                <div className={classes.title}>
                  <b>Email</b>
                </div>
                <Field
                  component={TextField}
                  type="email"
                  name="email"
                  variant="outlined"
                  className={classes.input}
                  placeholder="Email"
                />
              </>
            )}
            {emailIsPrimary && (
              <>
                <div className={classes.title}>
                  <b>Phone Number</b>
                </div>
                <Field
                  component={PhoneField}
                  name="phoneNumber"
                  variant="outlined"
                  className={classes.input}
                  placeholder="Phone Number"
                />
              </>
            )}
            {/*<div className={classes.title}>*/}
            {/*  <b>Birthday</b>*/}
            {/*</div>*/}
            {/*<Field*/}
            {/*  component={BirthdayField}*/}
            {/*  name="birthday"*/}
            {/*  placeholder="MM/DD/YYYY"*/}
            {/*  className={classes.input}*/}
            {/*  variant="outlined"*/}
            {/*/>*/}
            <Field
              component={CheckboxField}
              name="olderThanThirteen"
              label="I verify I am 13 years old or older"
              labelstyle={{ color: '#FF6F3E', font: 'normal normal normal 18px/24px Open Sans', marginTop: '15px' }}
            />
            <Button className={classes.buttonLogin} variant="contained" disabled={!isValid} type="submit">
              <div className={classes.loginText}>
                <b>Next</b>
              </div>
            </Button>
            <Button variant="text" color="primary" onClick={onBack}>
              Back
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default StepTwo
