import React, { BaseSyntheticEvent } from 'react'
import classNames from 'classnames'
import './index.scss'

interface TextareaProps {
  label?: string
  id?: string
  name?: string
  rows?: number
  cols?: number
  value?: string | any
  onChange?: (event: BaseSyntheticEvent) => void
  className?: string
  onBlur?: (event: BaseSyntheticEvent) => void
}

export const TextareaComponent = (props: TextareaProps) => {
  const classes = classNames({
    [`${props.className}`]: props.className
  })
  return (
    <div className="textarea_wrapper">
      {props.label && <label htmlFor={props.id}>{props.label}</label>}
      <textarea
        className={classes}
        id={props.id}
        name={props.name}
        rows={props.rows}
        cols={props.cols}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
      />
    </div>
  )
}
