import Loading from '../../components/Loading'
import { Button } from '@material-ui/core'
import React, { useState } from 'react'
import StepContainer from '../../request/components/Steps/StepContainer'
import * as _ from 'lodash'
import useFileManagementUpload from '../../hooks/useFileManagementUpload'
import TaskDetails from '../TaskDetails'
import './index.scss'

export default function UploadImageStep(props) {
  const { onSubmit, task } = props
  const [volunteerImage, setVolunteerImage] = useState(null)
  const {
    uploadFile,
    status: { loading: uploadingFile },
  } = useFileManagementUpload(task.organization.id)

  const upload = async (file, uploadedFor) => {
    const uploadedFile = await uploadFile(file, uploadedFor)
    setVolunteerImage(uploadedFile)
  }

  if (uploadingFile) {
    return <Loading />
  }

  return (
    <StepContainer header="Complete Task" disabled={!volunteerImage} nextStep={() => onSubmit(volunteerImage)}>
      <TaskDetails task={task} style={{ marginRight: '45px' }} />
      <input
        accept="image/*"
        id="file-upload-input"
        type="file"
        style={{ display: 'none' }}
        onChange={async (e) => upload(_.first(e.target.files), 'task_volunteer_feedback')}
      />
      <p>Please add a picture to show the task is complete.</p>
      <label htmlFor="file-upload-input">
        <Button variant="contained" color="primary" component="span" disabled={uploadingFile}>
          Add Image
        </Button>
      </label>
      {volunteerImage && <img src={volunteerImage.url} alt="Task Confirmation Image" />}
    </StepContainer>
  )
}
