import { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from '../api/hooks'

const useEnsureSignInVolunteerIsFirstRecipient = (signedInPerson, recipientList) => {
  return useMemo(() => {
    if (!signedInPerson || !recipientList) {
      return []
    }
    const signedInVolunteerIndex = recipientList.findIndex(
      (recipient) => Number(recipient.id) === Number(signedInPerson.id),
    )
    if (signedInVolunteerIndex > -1) {
      const signedInVolunteer = recipientList[signedInVolunteerIndex]
      recipientList.splice(signedInVolunteerIndex, 1)
      return [{ ...signedInVolunteer }, ...recipientList]
    } else {
      return [{ ...signedInPerson }, ...recipientList]
    }
  }, [signedInPerson, recipientList])
}

const useTaskRecipients = (signedInPerson) => {
  const [request, { result, loading, called }] = useFetch('Error fetching task recipients')
  const getTaskRecipients = useCallback(() => {
    return request({
      method: 'get',
      url: '/user/task-recipients',
    })
  }, [request])

  useEffect(() => {
    getTaskRecipients()
  }, [getTaskRecipients])

  const recipients = useEnsureSignInVolunteerIsFirstRecipient(signedInPerson, result)
  return {
    recipients,
    loading: loading || !called,
    refresh: getTaskRecipients,
  }
}

export default useTaskRecipients
