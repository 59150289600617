import React, { useState } from 'react'
import { Auth } from 'aws-amplify'
import { makeStyles } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

import { useErrorNotification } from '../../../api/hooks'
import Loading from '../../../components/Loading'
import StepOne from './StepOne'
import StepTwo from './StepTwo'

const useStyles = makeStyles((theme) => ({
  buttonLogin: {
    width: '285px',
    height: '61px',
    backgroundColor: '#FFCD00',
    borderColor: '#3D3D3D',
    borderWidth: '1px',
    marginTop: '38px',
    marginBottom: '45px',
  },
  loginText: {
    color: '#3D3D3D',
  },
  input: {
    width: '285px',
    marginBottom: '31px',
    borderColor: '#707070',
  },
  error: {
    color: 'red',
    fontSize: '11px',
    width: '285px',
    marginRight: '-20px',
    marginTop: '5px',
  },
  title: {
    fontSize: '21px',
    marginBottom: '8px',
    width: '285px',
    marginTop: '16px',
  },
  changeContact: {
    color: '#5C8DE8FA',
    fontSize: '16px',
    alignSelf: 'center',
  },
  container: {
    marginTop: '25px',
  },
}))

const ResetPassword = () => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [contact, setContact] = useState('')
  const classes = useStyles()
  const history = useHistory()
  const showError = useErrorNotification()

  const onReset = async (values) => {
    try {
      setLoading(true)
      setContact(values.email || values.phoneNumber)
      await Auth.forgotPassword(values.email || values.phoneNumber)
      setStep(2)
    } catch (err) {
      showError('There was a problem resetting your password', err)
    } finally {
      setLoading(false)
    }
  }

  const onNewPassword = async (values) => {
    try {
      setLoading(true)
      await Auth.forgotPasswordSubmit(contact, values.code, values.password)
      await Auth.signIn(contact, values.password)
      history.push('/receive-or-lend')
    } catch (err) {
      showError('There was a problem resetting your password', err)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.container}>
      {step === 1 && <StepOne onSubmit={onReset} classes={classes} />}
      {step === 2 && <StepTwo onSubmit={onNewPassword} classes={classes} />}
    </div>
  )
}

export default ResetPassword
