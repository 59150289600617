import React from 'react'
import { makeStyles } from '@material-ui/core'
import { Field } from 'formik'
import { TextField } from 'formik-material-ui'
import StepContainer from './StepContainer'

const useStyles = makeStyles(() => ({
  input: {
    width: '324px',
    height: '354px',
    borderColor: '#707070',
  },
}))

export default function TaskDescription(props) {
  const { value, nextStep } = props
  const classes = useStyles()

  return (
    <StepContainer
      header="Details"
      subHeader="Give us any additional details about the task"
      disabled={!value}
      nextStep={nextStep}
    >
      <Field
        component={TextField}
        multiline
        rows={15}
        rowsMax={20}
        name="description"
        placeholder="Please add additional details here"
        className={classes.input}
        variant="outlined"
      />
    </StepContainer>
  )
}
