import moment from 'moment'
import React from 'react'
import { makeStyles } from '@material-ui/core'
import NextButton from '../../../components/NextButton'
import StepContainer from './StepContainer'

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: '18px',
    marginBottom: '30px',
    marginTop: '30px',
  },
  frame: {
    display: 'flex',
    flexDirection: 'column',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    alignItems: 'center',
    alignSelf: 'center',
    border: '1px solid',
    width: 'calc(100% - 32px)',
  },
  serviceType: {
    fontSize: '26px',
    color: '#3D3D3D',
    marginTop: '18px',
  },
  person: {
    fontSize: '21px',
    color: '#3D3D3D',
  },
  simpleText: {
    fontSize: '18px',
    color: '#3D3D3D',
  },
  time: {
    fontSize: '21px',
    color: '#3D3D3D',
    alignSelf: 'center',
    marginTop: '46px',
  },
  details: {
    fontSize: '18px',
    marginTop: '12px',
    alignSelf: 'center',
  },
  timeText: {
    fontSize: '18px',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '16px',
  },
}))

export default function ConfirmTask(props) {
  const { values, loading } = props
  const { timeslots, description, recipient, serviceType } = values
  const classes = useStyles()
  return (
    <StepContainer
      header="Your Task"
      subHeader="This is your task. If it looks correct please tap confirm or tap the back arrow to update the task."
      loading={loading}
    >
      {/* after you submit, all the values get wiped away before the page changes - so, must hide when not loading*/}
      {!loading && (
        <div className={classes.frame}>
          <div className={classes.serviceType}>
            <b>{serviceType && serviceType.name}</b>
          </div>
          <div className={classes.simpleText}>for</div>
          <div className={classes.person}>
            <b>
              {recipient.givenName} {recipient.familyName}
            </b>
          </div>
          <div className={classes.time}>
            <b>Available Time</b>
          </div>
          {timeslots.map((timeSlot, index) => (
            <div className={classes.row} key={index}>
              <div className={classes.timeText}>
                {moment(timeSlot.startTime).format('MM/DD/YY hh:mm a')} - {moment(timeSlot.endTime).format('hh:mm a')}
              </div>
            </div>
          ))}
          <div className={classes.time}>
            <b>Details</b>
          </div>
          <div className={classes.details}>{description}</div>
        </div>
      )}
      <NextButton type="submit" text="Confirm" />
    </StepContainer>
  )
}
