import { Button, Dialog, makeStyles, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useCancelTask } from '../hooks/useTaskEvents'
import Loading from '../components/Loading'
import { buttonClasses } from '../components/buttonClasses'
import cn from 'classnames'

const useStyles = makeStyles((theme) => ({
  ...buttonClasses(theme),
  center: {
    textAlign: 'center',
  },
  dialog: {
    padding: theme.spacing(2),
  },
}))

export default function CancelModal(props) {
  const { task, open, onClose, complete } = props
  const [comment, setComment] = useState('')
  const [cancelTask, cancelLoading] = useCancelTask()
  const classes = useStyles()

  const handleClose = () => {
    setComment('')
    onClose()
  }

  const handleComplete = async () => {
    await cancelTask(task, { comment })
    complete()
  }

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      {cancelLoading ? (
        <Loading />
      ) : (
        <div className={classes.dialog}>
          <h1>Cancel Task</h1>
          <h2>Why do you need to cancel?*</h2>
          <TextField
            multiline
            rows={6}
            placeholder="Please tell us why you need to cancel"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            variant="outlined"
            fullWidth
          />
          <Button variant="text" color="primary" onClick={handleClose}>
            Never mind keep this task.
          </Button>
          <p>
            <b>Are you sure you want to cancel?</b>
          </p>
          <p>This sends the volunteer and the admin a message about the change</p>
          <div className={classes.center}>
            <Button
              varitant="contained"
              className={cn(classes.warningButton, classes.button)}
              onClick={handleComplete}
              disabled={!comment}
            >
              Cancel Task
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  )
}
