import { makeStyles } from '@material-ui/core'
import React from 'react'
import { Link } from 'react-router-dom'
import cn from 'classnames'

const useStyles = makeStyles((theme) => ({
  linkButton: (props) => ({
    width: props.fullWidth ? undefined : '224px',
    height: '84px',
    backgroundColor: props.variant === 'secondary' ? theme.palette.secondary.main : theme.palette.primary.main,
    color: props.variant === 'secondary' ? theme.palette.neutral.white : theme.palette.neutral.black,
    border: '1px solid black',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    textDecoration: 'none',
    margin: '32px',
    flexDirection: 'column',
    fontWeight: 600
  }),
  
  subtext: {
    fontWeight: 300
  }
}))

export default function LinkButton(props) {
  const { to, text, subtext, variant, className = '', fullWidth } = props
  const classes = useStyles({ variant, fullWidth })

  return (
    <Link to={to} className={cn(classes.linkButton, className)}>
      {text}
      {!!subtext && <span className={classes.subtext}>{subtext}</span>}
    </Link>
  )
}
