import { Button, makeStyles, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import Content from '../components/Content'
import { buttonClasses } from '../components/buttonClasses'
import cn from 'classnames'
import { ArrowBack } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import CompleteTask from './CompleteTask'
import TaskDetails from './TaskDetails'
import WithdrawModal from './WithdrawModal'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
  ...buttonClasses(theme),
  task: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
  },
  center: {
    textAlign: 'center',
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
}))

export default function AssignedTaskDetails(props) {
  const { task } = props
  const classes = useStyles()
  const history = useHistory()
  const [comment, setComment] = useState('')
  const [showCompleteTask, setShowCompleteTask] = useState(false)
  const [showWithdraw, setShowWithdraw] = useState(false)

  const handleCompleteTaskClick = () => {
    setShowCompleteTask(true)
  }

  if (showCompleteTask) {
    return <CompleteTask task={task} comment={comment} />
  }

  return (
    <>
      <WithdrawModal
        open={showWithdraw}
        onClose={() => setShowWithdraw(false)}
        task={task}
        complete={() => history.push('/lend-dashboard')}
      />
      <Content>
        <Link to="/lend-dashboard" className={classes.backLink}>
          <ArrowBack />
          Back to Dashboard
        </Link>
        <h1 className={classes.center}>Active Task</h1>
        <>
          <Button
            className={cn(classes.warningButton, classes.button)}
            variant="contained"
            onClick={handleCompleteTaskClick}
          >
            Complete Task
          </Button>
          <div className={classes.task}>
            <TaskDetails task={task} />
            <div>
              <h2>Details</h2>
              <div dangerouslySetInnerHTML={{ __html: task.description }} />
            </div>
          </div>

          <h2>Notes</h2>

          <TextField
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            multiline
            rows={6}
            label="Notes"
            placeholder="Please enter optional notes here"
            variant="outlined"
          />
          <Button varitant="contained" className={classes.button} onClick={() => setShowWithdraw(true)}>
            Withdraw
          </Button>
        </>
      </Content>
    </>
  )
}
