import { makeStyles } from '@material-ui/core'
import React from 'react'
import Content from '../components/Content'
import Loading from '../components/Loading'
import useAssignedTasks from '../hooks/useAssignedTasks'
import { isEmpty, sortBy, sumBy, round } from 'lodash'
import { buttonClasses } from '../components/buttonClasses'
import { taskIsInProgress, taskIsCompleted, taskIsDone, taskIsToday } from '../utils/taskUtils'
import LinkButton from '../components/LinkButton'
import { TaskCard } from './TaskCard'
import { Link } from 'react-router-dom'
import { mdiAccountCircleOutline } from '@mdi/js'
import Icon from '@mdi/react'

const useStyles = makeStyles((theme) => ({
  ...buttonClasses(theme),
  profileLink: {
    color: theme.palette.secondary.main,
    marginRight: '25px',
    marginTop: '25px',
    width: '100%',
    textAlign: 'end',
  },
}))

export default function LendDashboard(props) {
  const { assignedTasks: tasks, loading } = useAssignedTasks()
  const classes = useStyles()

  const orderedTasks = sortBy(tasks, (task) => task.startTime)
  const { activeTasks, assignedTasks } = orderedTasks.reduce(
    (acc, task) => {
      if (taskIsDone(task)) {
        return acc
      } else if (taskIsToday(task) || taskIsInProgress(task)) {
        acc.activeTasks.push(task)
      } else {
        acc.assignedTasks.push(task)
      }
      return acc
    },
    { activeTasks: [], assignedTasks: [] },
  )
  const completedTasks = orderedTasks.filter(taskIsCompleted)
  const totalHours = round(sumBy(completedTasks, 'duration')) || 0
  return (
    <Content>
      <Link to="/user-preferences" className={classes.profileLink}>
        <Icon path={mdiAccountCircleOutline} size={2} />
      </Link>
      <h1 style={{ textAlign: 'center' }}>Dashboard</h1>
      {loading ? (
        <Loading />
      ) : (
        <>
          <h2>Active</h2>
          {isEmpty(activeTasks) ? (
            <p>You don't have any active tasks</p>
          ) : (
            activeTasks.map((task) => <TaskCard key={task.id} task={task} active showStatus view="volunteer" />)
          )}
          <h2>Assigned</h2>
          {isEmpty(assignedTasks) ? (
            <p>You don't have any assigned tasks</p>
          ) : (
            assignedTasks.map((task) => <TaskCard key={task.id} task={task} scheduled view="volunteer" />)
          )}
          <h3>Completed Tasks: {completedTasks.length}</h3>
          <p>Total Hours: {totalHours}</p>
        </>
      )}
      <LinkButton
        to="/available-tasks"
        text="DISCOVER TASKS"
        className={classes.button}
        variant="secondary"
        fullWidth
      />
    </Content>
  )
}
