import { Button, makeStyles } from '@material-ui/core'
import React, { useState } from 'react'
import Content from '../components/Content'
import Loading from '../components/Loading'
import moment from 'moment-timezone'
import useTaskTimeslots from '../hooks/useTaskTimeslots'
import cn from 'classnames'
import { useAssignSelfTask } from '../hooks/useTaskEvents'
import { useHistory } from 'react-router'
import { buttonClasses } from '../components/buttonClasses'
import { ArrowBack } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import TaskDetails from './TaskDetails'
import TimeSlotPicker from './TimeSlotPicker'

const useStyles = makeStyles((theme) => ({
  ...buttonClasses(theme),
  taskPane: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
}))

const timeslotIsCurrent = (timeslot) => moment(timeslot.endTime).isAfter(moment())

export default function UnassignedTaskDetails(props) {
  const { task } = props
  const { timeslots, loading: timeslotsLoading } = useTaskTimeslots(task.id)
  const [timeslot, setTimeslot] = useState(null)
  const classes = useStyles()
  const [assignSelf] = useAssignSelfTask()
  const history = useHistory()
  const isSelected = (ts) => timeslot && timeslot.id === ts.id

  const handleTimeslotClick = (ts) => {
    if (timeslotIsCurrent(ts)) {
      setTimeslot(ts)
      isSelected(ts) ? setTimeslot(null) : setTimeslot(ts)
    }
  }

  async function onSubmit() {
    await assignSelf(task, {
      startTime: timeslot.startTime,
      endTime: timeslot.endTime,
    })
    history.push('/lend-dashboard')
  }

  return (
    <Content>
      <Link to="/available-tasks" className={classes.backLink}>
        <ArrowBack />
      </Link>
      <h1 style={{ textAlign: 'center' }}>Confirm Task</h1>
      {timeslotsLoading ? (
        <Loading />
      ) : (
        task && (
          <>
            <div className={classes.taskPane}>
              <TaskDetails task={task} />
              <h2>Details</h2>
              <div dangerouslySetInnerHTML={{ __html: task.description }} />

              <h2>Available Times</h2>
              <h4>Please select one</h4>
              <TimeSlotPicker value={timeslot} options={timeslots} onChange={setTimeslot} task={task} />
            </div>
            <p>This sends the requester and the administrator a message.</p>
            <Button
              variant="contained"
              color="primary"
              onClick={onSubmit}
              disabled={!timeslot}
              className={classes.button}
            >
              I Can Lend a Hand
            </Button>
          </>
        )
      )}
    </Content>
  )
}
