export const UPDATE_TIME_SLOTS = 'recipient.update_time_slots';
export const UPDATE_CURRENT_RECIPIENT = 'recipient.update_current_recipient';
export const UPDATE_CONTACT_PERSON = 'recipient.update_contact_person';
export const UPDATE_SERVICE_TYPE = 'recipient.update_service_type';
export const UPDATE_DETAILS = 'recipient.update_details';

export function updateTimeSlots(timeSlot){
    return {
        type: UPDATE_TIME_SLOTS,
        payload: {timeSlot}
    }
}

export function updateCurrentRecipient(currentRecipient){
    return {
        type: UPDATE_CURRENT_RECIPIENT,
        payload: {currentRecipient}
    }
}

export function updateContactPerson(contactPerson){
    return {
        type: UPDATE_CONTACT_PERSON,
        payload: {contactPerson}
    }
}

export function updateServiceType(serviceType){
    return {
        type: UPDATE_SERVICE_TYPE,
        payload: {serviceType}
    }
}

export function updateDetails(details){
    return {
        type: UPDATE_DETAILS,
        payload: {details}
    }
}