import { useCallback, useEffect } from 'react'
import { useFetch } from '../api/hooks'

const STABLE_EMPTY_OBJECT = {}
const useTask = (taskId) => {
  const [request, { result: task, loading, called }] = useFetch(
    'Error fetching task.  Please refresh the page and try again',
  )

  const getTask = useCallback(async () => {
    return request({
      method: 'get',
      url: `/task/${taskId}`,
    })
  }, [taskId, request])

  useEffect(() => {
    getTask()
  }, [getTask])

  return {
    task: task || STABLE_EMPTY_OBJECT,
    loading: loading || !called,
    refresh: getTask,
  }
}

export default useTask
