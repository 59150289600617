import { useCallback } from 'react'
import { useFetch } from '../api/hooks'

const useCreatePersonLocation = () => {
  const [request, status] = useFetch('Error creating location')
  const createPersonLocation = useCallback(
    (personId, payload) => {
      return request({
        method: 'post',
        url: `/persons/${personId}/locations`,
        config: {
          body: payload,
        },
      })
    },
    [request],
  )

  return [createPersonLocation, status]
}

export default useCreatePersonLocation
