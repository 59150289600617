export const buttonClasses = (theme) => ({
    button: {
        height: theme.spacing(6),
        margin: theme.spacing(2)
    },
    warningButton: {
        backgroundColor: theme.palette.warning.main,
        color: theme.palette.neutral.white,
        '&:hover': {
            backgroundColor: theme.palette.warning.dark
        },
        '&:disabled': {
            backgroundColor: theme.palette.neutral.white,
        }
    },
})