import { get } from 'lodash'
import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/styles'
import { Button, Grid } from '@material-ui/core'
import ReactGoogleMap from '../civic-champs-shared/core/location/ReactGoogleMap'
import useOrgDetailsById from '../hooks/useOrgDetailsById'
import Loading from '../components/Loading'
import Content from '../components/Content'
import useJoinOrgAsVolunteer from '../hooks/useJoinOrgAsVolunteer'
import { useHistory } from 'react-router'
import { useErrorNotification } from '../api/hooks'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import RequirementsModal from 'civic-champs-shared/question-sets/components/RequirementsModal'
import { REQUIRED_GROUP_TYPES } from '../api/utils/constants'
import { PrerequisitesAction } from 'civic-champs-shared/question-sets/types'
import useGroupMemberships from '../hooks/useGroupMemberships'
import { friendlyVolunteerMembershipStatusCode } from '../utils/membership-constants'

const useStyles = makeStyles((theme) => ({
  logo: {
    maxWidth: '64px',
    maxHeight: '64px',
  },
  serviceType: {
    fontSize: '1.3rem',
  },
  mapContainer: {
    height: '30vh',
  },
  applyButton: {
    margin: theme.spacing(2),
  },
  membership: {
    fontStyle: 'italic',
    textAlign: 'center',
  },
}))

const useMembership = (user, orgId) => {
  const { memberships, loading } = useGroupMemberships({
    groupType: REQUIRED_GROUP_TYPES.volunteer,
    personId: user.id,
  })
  const membership = useMemo(
    () => !loading && memberships.find((membership) => membership.organization.id === Number(orgId)),
    [memberships, loading, orgId],
  )
  return { membership, loading }
}

export default function OrgDetails(props) {
  const { user } = props
  const { orgId } = props.match.params
  const { orgDetails, loading: orgDetailsLoading } = useOrgDetailsById(orgId)
  const { membership, loading: membershipsLoading } = useMembership(user, orgId)
  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)
  const { joinOrgAsVolunteer, loading: joinLoading } = useJoinOrgAsVolunteer()
  const history = useHistory()
  const showError = useErrorNotification()

  const handleRequirements = async () => {
    try {
      return await fulfillRequirementsPrompt({
        person: user,
        filters: {
          associatedEntity: { requiredGroup: REQUIRED_GROUP_TYPES.volunteer, organizationId: orgId },
          action: PrerequisitesAction.GROUP_SIGN_UP,
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
        },
        continueEvenIfError: false,
      })
    } catch (error) {
      return false
    }
  }

  const joinOrg = async () => {
    try {
      if (await handleRequirements()) {
        await joinOrgAsVolunteer(orgId)
      }
      history.push('/lend-dashboard')
    } catch (err) {
      showError('There was a problem applying to the organization', err)
    }
  }

  const classes = useStyles()
  const loading = orgDetailsLoading || membershipsLoading || joinLoading
  if (loading) {
    return <Loading />
  }

  //TODO location is not coming down with the proper coordinates?
  const { serviceTypes = [], location = {}, logoUrl, name, description } = orgDetails
  return (
    <Content>
      <Grid container direction="column" alignItems="center">
        <Grid>
          <h1>{name}</h1>
        </Grid>
        {logoUrl && (
          <Grid>
            <img src={logoUrl} alt={`${name} logo`} className={classes.logo} />
          </Grid>
        )}
        {description && (
          <Grid>
            <p>{description}</p>
          </Grid>
        )}
      </Grid>
      <Grid container direction="row">
        <Grid item xs={3} />
        <Grid item xs={6}>
          <h2>Service Types</h2>
          <ul>
            {serviceTypes.map((st, index) => (
              <li className={classes.serviceType} key={`${st.name}-${index}`}>
                {st.name}
              </li>
            ))}
          </ul>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={1} md={3} />

        <Grid item xs={10} md={6} className={classes.mapContainer}>
          <ReactGoogleMap
            coordinates={get(location, 'coordinates', [])}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: '100%' }} />}
            mapElement={<div style={{ height: `100%` }} />}
          />
        </Grid>
      </Grid>
      {membership && (
        <p className={classes.membership}>
          Membership Status: {friendlyVolunteerMembershipStatusCode[membership.status.code]}
        </p>
      )}
      {!membership && (
        <Button color="primary" variant="contained" className={classes.applyButton} onClick={() => joinOrg()}>
          Apply to Volunteer
        </Button>
      )}
    </Content>
  )
}
