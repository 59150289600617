import { Button, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import Content from '../components/Content'
import { useStartTask, useVolunteerRescheduleRequest } from '../hooks/useTaskEvents'
import { useHistory } from 'react-router'
import Loading from '../components/Loading'
import { buttonClasses } from '../components/buttonClasses'
import cn from 'classnames'
import { ArrowBack } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import TaskDetails from './TaskDetails'
import WithdrawModal from './WithdrawModal'
import useTaskTimeslots from '../hooks/useTaskTimeslots'
import TimeSlotPicker from './TimeSlotPicker'
import HealthCheckDialog from './HealthCheckDialog'

const useStyles = makeStyles((theme) => ({
  ...buttonClasses(theme),
  task: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
  },
  center: {
    textAlign: 'center',
  },
  warning: {
    color: theme.palette.warning.main,
  },
  dialog: {
    padding: theme.spacing(2),
  },
  directions: {
    color: theme.palette.secondary.main,
    display: 'flex',
    alignContent: 'center',
    justifyContent: 'center',
    fontSize: '1.2rem',
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  yellowButton: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.black,
  },
}))

export default function AssignedTaskDetails(props) {
  const { task, refreshTask } = props
  const { timeslots, loading: timeslotsLoading } = useTaskTimeslots(task.id)
  const [showHealthCheck, setShowHealthCheck] = useState(false)
  const [showWithdraw, setShowWithdraw] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const [startTask, startTaskLoading] = useStartTask()
  const [rescheduleTask, rescheduleTaskLoading] = useVolunteerRescheduleRequest()
  const history = useHistory()
  const classes = useStyles()
  const [timeslot, setTimeslot] = useState(null)

  useEffect(() => {
    if (!timeslotsLoading && task.startTime && task.endTime) {
      setTimeslot(timeslots.find((ts) => task.startTime === ts.startTime && task.endTime === ts.endTime))
    }
  }, [timeslotsLoading])
  
  // https://civicchamps.atlassian.net/browse/CCA-1422
  const taskCanStart = () => true
  const onStartTaskClick = () => setShowHealthCheck(true)
  const handleWithdrawClick = () => setShowWithdraw(true)

  const handleUpdateClick = async () => {
    await rescheduleTask(task, timeslot)
    refreshTask()
  }

  const onHealthCheckComplete = async () => {
    await startTask(task)
    refreshTask()
  }

  const loading = timeslotsLoading || startTaskLoading || rescheduleTaskLoading
  if (loading) {
    return <Loading />
  }

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Content>
          {!editMode && (
            <Link to="/lend-dashboard" className={classes.backLink}>
              <ArrowBack />
              Back to Dashboard
            </Link>
          )}
          {editMode && <ArrowBack className={classes.backLink} onClick={() => setEditMode(false)} />}
          <h1 className={classes.center}>Assigned Task</h1>
          <div className={classes.task}>
            <div className={classes.center}>
              <TaskDetails task={task} hideTaskTime={editMode} />
            </div>
            {editMode && (
              <div>
                <h2>Select a time</h2>
                <TimeSlotPicker value={timeslot} options={timeslots} task={task} onChange={setTimeslot} />
              </div>
            )}
            <div>
              <h2>Details</h2>
              <div dangerouslySetInnerHTML={{ __html: task.description }} />
            </div>
          </div>
          <WithdrawModal
            open={showWithdraw}
            onClose={() => setShowWithdraw(false)}
            task={task}
            complete={() => history.push('/lend-dashboard')}
          />
          <HealthCheckDialog
            open={showHealthCheck}
            onClose={() => {
              setShowHealthCheck(false)
            }}
            onSubmit={onHealthCheckComplete}
            task={task}
          />
          {!editMode && (
            <>
              <Button variant="text" color="primary" onClick={() => setEditMode(true)}>
                Edit Time
              </Button>
              {!taskCanStart() && <p className={classes.warning}>Currently outside the time you agreed</p>}
              <Button
                variant="contained"
                color="secondary"
                disabled={!taskCanStart()}
                onClick={onStartTaskClick}
                className={classes.button}
              >
                Start Now
              </Button>
            </>
          )}
          {editMode && (
            <div className={classes.row}>
              <Button
                varitant="contained"
                className={cn(classes.warningButton, classes.button)}
                onClick={handleWithdrawClick}
              >
                Withdraw
              </Button>
              <Button
                varitant="contained"
                className={cn(classes.button, classes.yellowButton)}
                disabled={!timeslot}
                onClick={handleUpdateClick}
              >
                Update
              </Button>
            </div>
          )}
        </Content>
      )}
    </>
  )
}
