export const browserIsSafari = () => {
    const userAgentString = navigator.userAgent
    let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    let safariAgent = userAgentString.indexOf("Safari") > -1; 
  
    // Discard Safari since it also matches Chrome 
    if ((chromeAgent) && (safariAgent)) safariAgent = false; 
    return safariAgent
}

export default browserIsSafari