import * as _ from 'lodash'
import { QuestionType, Question, QuestionSetResponse, AnswerPayload, QuestionPayload } from '../types'
import { omit } from 'lodash'

export const questionTypeCodeToDefaultAnswer = {
  [QuestionType.SHORT_TEXT]: '',
  [QuestionType.LONG_TEXT]: '',
  [QuestionType.SINGLE_CHOICE]: '',
  [QuestionType.DROPDOWN]: '',
  [QuestionType.MULTIPLE_CHOICE]: [],
  [QuestionType.TRUE_OR_FALSE]: false,
  [QuestionType.NUMBER]: 0,
}

export interface ResponseByQuestionId {
  [key: string]: any
}

const getResponse = (question: Question) =>
  _.isEmpty(question.response) ? questionTypeCodeToDefaultAnswer[question.questionType] : question.response

export const initQuestionAnswers = (questionSetResponse: QuestionSetResponse): Question[] => {
  if (!questionSetResponse) {
    return []
  }
  const { questions } = questionSetResponse

  const responseByQuestionId: ResponseByQuestionId = questions.reduce(
    (acc: ResponseByQuestionId, question: Question) => {
      acc[question.questionId] = getResponse(question)
      return acc
    },
    {},
  )
  return questions.map(question => {
    const { conditionedOnQuestionId, conditionedOnAnswer, otherAnswer } = question
    return {
      ...question,
      response: getResponse(question),
      otherAnswer: _.isEmpty(otherAnswer) ? '' : otherAnswer,
      disabled: conditionedOnQuestionId ? responseByQuestionId[conditionedOnQuestionId] !== conditionedOnAnswer : false,
    }
  })
}

export const mapQuestionToAnswerPayload = (questionWithAnswer: Question): AnswerPayload => ({
  answerSetId: _.get(questionWithAnswer, 'answerSet.id', undefined),
  answerId: _.get(questionWithAnswer, 'answerId', undefined),
  questionId: questionWithAnswer.questionId,
  response: questionWithAnswer.disabled
    ? questionTypeCodeToDefaultAnswer[questionWithAnswer.questionType]
    : questionWithAnswer.response,
  otherAnswer: questionWithAnswer.disabled ? '' : questionWithAnswer.otherAnswer,
  completed: questionWithAnswer.disabled ? false : !_.isEmpty(questionWithAnswer.response),
})

// shouldn't matter so much, but when editing an existing question, the existing question will be populated, but the questionPayload shouldn't be
const mapQuestionToQuestionPayload = (question: Question): QuestionPayload => {
  const { organization, createdBy } = question
  return omit({
    ...question,
    organizationId: organization && organization.id,
    createdById: createdBy && createdBy.id
  }, ['organization', 'createdBy'])
}

export const OTHER_OPTION = 'Other'
