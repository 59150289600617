import * as _ from 'lodash'
import * as yup from 'yup'

const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89AB][0-9a-f]{3}-[0-9a-f]{12}$/i

yup.addMethod(yup.string, 'uuid', function () {
  return this.matches(UUID_REGEX, 'is not a UUID')
})

yup.addMethod(yup.string, 'phoneNumber', function () {
  return this.transform(function (value) {
    if (!value) {
      // will cause issues if the field isn't nullable()
      return null
    }
    // stripping away all '+' and '-'
    const interimValue = value.replace(/\D/g, '')
    if (interimValue === '1') {
      // this will happen if you click into the input mask and click out
      return null
    }
    // ensuring +1 is always there
    const prefix = interimValue.length === 11 ? '+' : '+1'
    return prefix + interimValue
  }).test({
    name: 'phoneNumber',
    message: '${path} must be a valid US phone number',
    exclusive: true,
    test: (value) => !value || /([+][1][2-9][0-9]{9})/.test(value),
  })
})

// https://github.com/jquense/yup/issues/248#issuecomment-491995950
yup.addMethod(yup.object, 'atLeastOneOf', function (list) {
  return this.test({
    name: 'atLeastOneOf',
    message: '${path} must have at least one of: ${keys}',
    exclusive: true,
    params: { keys: list.join(', ') },
    test: (value) => list.some((key) => value[key]),
  })
})

yup.addMethod(yup.string, 'name', function () {
  return this.transform(function (value) {
    if (!value) {
      return value
    }
    return value && _.trim(_.upperFirst(value))
  })
})

export default yup
