import { get } from 'lodash'
import { SIGNED_IN, SIGNED_OUT, AUTH_INITIALIZED } from './actions';

const defaultState = { 
    initialized: false,
    currentUser: null,
    currentOrganization: null 
};

const getOrganization = (user) => get(user, 'permissions.managedOrganization')

export default function(state=defaultState, event) {
    switch(event.type) {
        case AUTH_INITIALIZED:
            return { 
                initialized: true,
                currentUser: event.payload.user,
                currentOrganization: getOrganization(event.payload.user)
            };

        case SIGNED_IN: 
            return { 
                initialized: true,
                currentUser: event.payload.user,
                currentOrganization: getOrganization(event.payload.user)
            };
        
        case SIGNED_OUT: 
            return { 
                ...state,
                currentUser: null,
                currentOrganization: null 
            }

        // case START_MANAGING_ORG:
        //     return { ...state, currentOrganization: event.payload.organization }

        default:
            return state;
    }
}