import { useState, useEffect, useMemo, useCallback } from 'react'
import { Hub } from '@aws-amplify/core'
import { Auth } from 'aws-amplify'
import { useFetchPerson } from 'civic-champs-shared/question-sets/hooks'
import { useAsyncEffect } from 'use-async-effect'

function useUserStatus() {
  const [user, setUser] = useState(null)
  const [loggedIn, setLoggedIn] = useState(!!Auth.user)
  const [loading, setLoading] = useState(true)
  const [fetchPerson] = useFetchPerson()

  const logOut = useCallback(() => {
    setLoggedIn(false)
    setUser(null)
    setLoading(false)
    localStorage.clear()
  }, [])

  const login = useCallback(() => {
    setLoading(true)
    fetchPerson()
      .then((res) => {
        setUser(res)
        setLoggedIn(true)
        setLoading(false)
      })
      .catch(() => {
        logOut()
      })
  }, [])

  // attempt to login in case there is a saved localStorage token
  useAsyncEffect(async () => {
    if ((!loggedIn || !user) && JSON.stringify(localStorage).includes('Cognito')) {
      try {
        await Auth.currentAuthenticatedUser()
        await login()
      } catch {
        await logOut()
      }
    } else {
      setLoading(false)
    }
  }, [])

  // listen for login or logout events
  useEffect(() => {
    const updateLoggedInStatus = async (data) => {
      const { payload } = data
      if (payload.event === 'signIn') {
        login()
      } else if (payload.event === 'signOut') {
        logOut()
      }
    }
    Hub.listen('auth', updateLoggedInStatus) // listen for login/signup events
    return () => Hub.remove('auth', updateLoggedInStatus) // cleanup
  }, [])

  return useMemo(() => ({ user, loading, loggedIn }), [user, loading, loggedIn])
}

export default useUserStatus
