import React from 'react'
import { Grid } from '@material-ui/core'

const valueToImage = {
  [-1]: '/assets/icons/emoticon-frown-outline',
  [-0.5]: '/assets/icons/emoticon-sad-outline',
  [0.5]: '/assets/icons/emoticon-happy-outline',
  [1]: '/assets/icons/emoticon-excited-outline',
}

export default function VolunteerRatingField(props) {
  const {
    form: { setFieldValue },
    field: { name, value },
    label,
  } = props
  return (
    <div>
      <label>{label}</label>
      <Grid container direction="row" justify="space-around" alignItems="center">
        {[-1, -0.5, 0.5, 1].map((rating) => {
          const selected = value === rating
          const src = selected ? `${valueToImage[rating]}-2.png` : `${valueToImage[rating]}.png`
          return (
            <Grid item key={rating}>
              <button
                style={{ width: '52px', height: '65px', border: 'none', background: 'none' }}
                key={rating}
                type="button"
                onClick={() => setFieldValue(name, rating)}
              >
                <img src={src} alt={rating} style={{ border: 'none', background: 'none' }} />
              </button>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}
