import { makeStyles } from '@material-ui/core'
import React from 'react'
import cn from 'classnames'
import moment from 'moment-timezone'

const useStyles = makeStyles((theme) => ({
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.neutral.white,
  },
  disabled: {
    color: theme.palette.neutral.lightGray,
    borderColor: theme.palette.neutral.lightGray,
    cursor: 'not-allowed',
  },
  timeslot: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    cursor: 'pointer',
    margin: theme.spacing(2),
  },
}))

export default function TimeSlotPicker(props) {
  const { onChange, value, options, task } = props
  const classes = useStyles()
  const timeZone = task.organization.timeZone || 'America/New_York'
  const isSelected = (ts) => value && value.id === ts.id
  const handleTimeslotClick = (ts) => {
    if (timeslotIsCurrent(ts)) {
      onChange(ts)
      isSelected(ts) ? onChange(null) : onChange(ts)
    }
  }
  const timeslotIsCurrent = (timeslot) => moment(timeslot.endTime).isAfter(moment())

  return (
    <div>
      {options.map((ts) => (
        <div
          key={ts.startTime}
          className={cn(classes.timeslot, {
            [classes.selected]: isSelected(ts),
            [classes.disabled]: !timeslotIsCurrent(ts),
          })}
          onClick={() => handleTimeslotClick(ts)}
        >
          {moment(ts.startTime).tz(timeZone).format('MM/DD/YY hh:mm a')} -{' '}
          {moment(ts.endTime).tz(timeZone).format('hh:mm a')}
        </div>
      ))}
    </div>
  )
}
