import React from 'react'
import { FormControl, FormHelperText } from '@material-ui/core'

export default function CheckboxField(props) {
  const { form, field, labelstyle = {}, label } = props
  const { name, value } = field
  const helperText = form.errors[name]
  const error = !!helperText

  return (
    <FormControl error={error} className="checkbox-container">
      <label style={labelstyle}>
        <input
          type="checkbox"
          {...props}
          className="checkbox-input"
          checked={value}
          onChange={() => form.setFieldValue(name, !value)}
        />
        {label}
      </label>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}
