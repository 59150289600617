import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import '@aws-amplify/ui/dist/style.css'
import './config-amplify'
import { createMuiTheme } from '@material-ui/core/styles'
import { ThemeProvider } from '@material-ui/styles'
import { CssBaseline } from '@material-ui/core'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { createStore, applyMiddleware, compose } from 'redux'
import storage from 'redux-persist/lib/storage'
import { ConnectedRouter, routerMiddleware } from 'connected-react-router'
import thunkMiddleware from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import history from './services/history'
import createRootReducer from './redux/reducers'

const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Open Sans',
    useNextVariants: true,
  },
  palette: {
    primary: { main: '#FFCD00' },
    secondary: { main: '#5C8DE8' },
    background: {
      default: '#ffffff',
      gray: '#efefef',
      lightGray: '#EEEEEE',
      blue: '#eff4fd',
      yellow: '#fef0b2',
    },
    accent: {
      yellowGreen: { main: '#bfdf57', dark: '#9bb548' },
      lightBlue: { main: '#add7f3', dark: '#82a4ba' },
    },
    success: { main: '#008000', light: '#98FB98' },
    danger: { main: '#ff6f3e', dark: '#b5502d' },
    neutral: {
      main: '#999999',
      darkGray: '#3d3d3d',
      black: '#000000',
      white: '#fff',
      lightGray: '#cccccc',
    },
  },
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, createRootReducer(history))

const store = createStore(
  persistedReducer, // root reducer with router state
  compose(applyMiddleware(routerMiddleware(history), thunkMiddleware)),
)

let persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={muiTheme}>
      <CssBaseline />

      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ConnectedRouter history={history}>
            <App />
          </ConnectedRouter>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
)
