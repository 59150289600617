import { UPDATE_TIME_SLOTS, UPDATE_CURRENT_RECIPIENT, UPDATE_CONTACT_PERSON, UPDATE_SERVICE_TYPE, UPDATE_DETAILS} from './actions';

export default function(state={ 
    timeSlots: [],
}, event) {
    switch(event.type) {
        case UPDATE_TIME_SLOTS: 
        let { timeSlot } = event.payload;

        return {
            ...state,
            timeSlots: timeSlot
        };
        
        case UPDATE_CURRENT_RECIPIENT: 
        let { currentRecipient } = event.payload;

        return {
            ...state,
            currentRecipient: currentRecipient
        }

        case UPDATE_CONTACT_PERSON: 
        let { contactPerson } = event.payload;

        return {
            ...state,
            contactPerson: contactPerson
        }

        case UPDATE_SERVICE_TYPE: 
        let { serviceType } = event.payload;

        return {
            ...state,
            serviceType: serviceType
        }

        case UPDATE_DETAILS: 
        let { details } = event.payload;

        return {
            ...state,
            details: details
        }

        default:
            return state;
    }
}