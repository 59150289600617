import { isFunction } from 'lodash'
import { useState, useEffect, useRef } from 'react'

import { parseGoogleResponse, Place } from './utils'

export type OnPlaceChangedHandler = (place: Place) => void

export default function useGoogleMapsAutocomplete(onPlaceChanged: OnPlaceChangedHandler) {
  const [element, setElement] = useState<HTMLInputElement | undefined>()
  const autocomplete = useRef<any>()

  useEffect(() => {
    if (!element) return

    autocomplete.current = new window.google.maps.places.Autocomplete(element, { types: ['geocode'] })
    autocomplete.current.setComponentRestrictions({ country: ['us'] })

    return () => {
      window.google.maps.event.clearInstanceListeners(autocomplete.current)
      autocomplete.current = null
    }
  }, [element])

  useEffect(() => {
    if (!autocomplete.current || !isFunction(onPlaceChanged)) return

    const onChange = () => {
      if (!autocomplete.current) return

      const place = autocomplete.current.getPlace()
      if (!place.geometry) {
        onPlaceChanged(null)
      } else if (place.address_components) {
        const place = parseGoogleResponse(autocomplete.current)
        onPlaceChanged(place)
      }
    }

    const listener = autocomplete.current.addListener('place_changed', onChange)

    return () => {
      listener.remove()
    }
  }, [onPlaceChanged, element])

  return (inputElem: HTMLInputElement) => setElement(inputElem)
}
