import { get, set } from 'lodash'
import React, { FC } from 'react'
import { Field, Formik } from 'formik'

import yup from 'utils/yup'
import { CreatePersonLocationPayload, LocationPayload, Place } from 'civic-champs-shared/core/location/utils'
import { GoogleMapsAutocompleteField } from 'civic-champs-shared/core/location/GoogleMapsAutocompleteField'
import { InputComponentField } from 'civic-champs-shared/core/location/InputComponentField'
import ReactGoogleMap from 'civic-champs-shared/core/location/ReactGoogleMap'
import NextButton from '../../../../../components/NextButton'

import './LocationForm.scss'

export interface Props {
  onSubmit: (location: CreatePersonLocationPayload) => void
}

export interface Values {
  place: Place | null
  line2?: string
  nickname?: string
}

const locationFormSchema = yup.object({
  place: yup
    .object({
      displayName: yup.string().required(),
      address: yup.object().required(),
      coordinates: yup.array().of(yup.number()).required(),
    })
    .nullable()
    .required(),
  line2: yup.string().nullable(),
  nickname: yup.string().nullable(),
})

export const LocationForm: FC<Props> = ({ onSubmit }) => {
  const handleSubmit = async (values: Values) => {
    const displayName = get(values, 'place.displayName')
    const label = get(values, 'nickname', displayName)

    let { line1, line2, ...address } = get(values, 'place.address')

    set(address, 'line1', line1)
    if (values.line2) {
      set(address, 'line2', values.line2)
    }

    const location: LocationPayload = {
      name: displayName,
      address,
      geofencing: {
        position: {
          coordinates: get(values, 'place.coordinates'),
          type: 'Point',
        },
      },
    }

    await onSubmit({ label, location })
  }

  return (
    <Formik
      initialValues={
        {
          place: null,
          line2: '',
          nickname: '',
        } as Values
      }
      validationSchema={locationFormSchema}
      isInitialValid={false}
      onSubmit={handleSubmit}
    >
      {({ values, isValid, submitForm, isSubmitting }) => (
        <div className="location-module">
          <div className="location-form-wrap">
            <div className="location-map">
              <ReactGoogleMap zoom={15} coordinates={get(values, 'place.coordinates', [])} />
            </div>
            <div className="location-form">
              <div className="location-form-row">
                <Field
                  name="place"
                  component={GoogleMapsAutocompleteField}
                  label="Address*"
                  placeholder="Street Address, City, State, Zip"
                />
              </div>
              <div className="location-form-row">
                <Field name="line2" component={InputComponentField} label="Apt or Suite" />
              </div>
              <div className="location-form-row">
                <Field name="nickname" component={InputComponentField} label="Nickname (Optional)" />
              </div>
            </div>
          </div>
          <div>
            <NextButton disabled={!isValid || isSubmitting} onClick={submitForm} />
          </div>
        </div>
      )}
    </Formik>
  )
}
