import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { stepThreeSchema } from './schemas'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import CheckboxField from '../../../components/CheckboxField'
import { useSuccessNotification, useErrorNotification } from 'civic-champs-shared/api/hooks'
import { Auth } from 'aws-amplify'

function StepThree(props) {
  const { onSubmit, classes, username } = props
  const showSuccess = useSuccessNotification()
  const showError = useErrorNotification()
  const onResend = async () => {
    try {
      await Auth.resendSignUp(username)
      showSuccess('Successfully resent code')
    } catch (err) {
      showError('Error resending code')
    }
  }
  return (
    <Formik
      initialValues={{
        code: '',
        acceptsCharges: false,
      }}
      validationSchema={stepThreeSchema}
      onSubmit={(values) => onSubmit(stepThreeSchema.cast(values))}
      validateOnBlur
    >
      {({ isValid }) => (
        <Form>
          <Grid container spacing={2} direction="column" justify="center" alignItems="center">
            <div className={classes.title}>
              <b>Verification Code</b>
            </div>
            <Field
              component={TextField}
              name="code"
              placeholder="Verification Code"
              className={classes.input}
              variant="outlined"
            />
            <Button onClick={onResend} variant="text">
              Resend Code
            </Button>
            <Field
              component={CheckboxField}
              name="acceptsCharges"
              label="I agree to receive phone calls & text messages if needed for communication during tasks and events*"
              labelstyle={{ font: 'normal normal normal 18px/24px Open Sans', marginTop: '15px' }}
            />
            <Button className={classes.buttonLogin} variant="contained" disabled={!isValid} type="submit">
              <div className={classes.loginText}>
                <b>Submit</b>
              </div>
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default StepThree
