import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import cn from 'classnames'
import { friendlyVolunteerMembershipStatusCode } from '../utils/membership-constants'

const useStyles = makeStyles((theme) => ({
  card: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: '4px',
    cursor: 'pointer',
    margin: theme.spacing(2),
    padding: theme.spacing(2),
    '&:hover': {
      background: theme.palette.secondary.main,
      color: theme.palette.neutral.white,
    },
  },
  alreadyMember: {
    background: theme.palette.background.lightGray,
  },
  selected: {
    background: theme.palette.secondary.main,
    color: theme.palette.neutral.white,
  },
  logo: {
    maxWidth: '64px',
    maxHeight: '64px',
  },
  membership: {
    fontStyle: 'italic',
  },
}))

export default function OrganizationCard(props) {
  const { org, onClick, isSelected, membership } = props
  const classes = useStyles()
  return (
    <Grid
      container
      className={cn({
        [classes.card]: true,
        [classes.alreadyMember]: !!membership,
        [classes.selected]: isSelected,
      })}
      onClick={() => onClick(org)}
    >
      <Grid item xs={3}>
        {org.logoUrl && <img src={org.logoUrl} alt={`${org.name} logo`} className={classes.logo} />}
      </Grid>
      <Grid item xs={9}>
        <h2>{org.name}</h2>
        <p>{org.serviceTypes.map((st) => st.name).join(', ')}</p>
        {membership && (
          <p className={classes.membership}>
            Volunteer Status: {friendlyVolunteerMembershipStatusCode[membership.status.code]}
          </p>
        )}
      </Grid>
    </Grid>
  )
}
