import yup from '../../../utils/yup'

export const stepOneSchema = yup
  .object({
    email: yup.string().email().trim().lowercase().nullable(),
    phoneNumber: yup.string().phoneNumber().label('Phone Number').nullable(),
  })
  .atLeastOneOf(['email', 'phoneNumber'])

export const stepTwoSchema = yup.object({
  code: yup.string().required(),
  password: yup.string().min(8).label('Password').required('Password is required'),
  passwordConfirm: yup
    .string()
    .label('Confirm Password')
    .oneOf([yup.ref('password'), null], 'Password does not match')
    .required('Retype Password is required'),
})
