import { useCallback, useEffect } from 'react'
import { useFetch } from '../api/hooks'

const STABLE_EMPTY_OBJECT = {}
const useVolunteerAvailability = () => {
  const [request, { result: volunteerAvailability, loading, called }] = useFetch(
    'Error fetching your volunteer availability',
  )

  const getAvailability = useCallback(async () => {
    return request({
      method: 'get',
      url: `/volunteer-availability`,
    })
  }, [request])

  useEffect(() => {
    getAvailability()
  }, [getAvailability])

  return {
    volunteerAvailability: volunteerAvailability || STABLE_EMPTY_OBJECT,
    loading: loading || !called,
    refresh: getAvailability,
  }
}

export default useVolunteerAvailability
