import { Button, Dialog, makeStyles, TextField } from '@material-ui/core'
import React, { useState } from 'react'
import { useVolunteerDropTask } from '../hooks/useTaskEvents'
import Loading from '../components/Loading'
import { buttonClasses } from '../components/buttonClasses'
import cn from 'classnames'

const useStyles = makeStyles((theme) => ({
  ...buttonClasses(theme),
  center: {
    textAlign: 'center',
  },
  dialog: {
    padding: theme.spacing(2),
  },
}))

export default function WithdrawModal(props) {
  const { task, open, onClose, complete } = props
  const [withdrawComment, setWithdrawComment] = useState('')
  const [volunteerDropTask, dropTaskLoading] = useVolunteerDropTask()
  const classes = useStyles()

  const handleWithdrawClose = () => {
    setWithdrawComment('')
    onClose()
  }

  const handleWithdrawComplete = async () => {
    await volunteerDropTask(task, { comment: withdrawComment })
    complete()
  }

  return (
    <Dialog open={open} onClose={onClose} className={classes.dialog}>
      {dropTaskLoading ? (
        <Loading />
      ) : (
        <div className={classes.dialog}>
          <h1>Withdraw</h1>
          <h2>Why do you need to withdraw?*</h2>
          <TextField
            multiline
            rows={6}
            placeholder="Please tell us why you need to withdraw"
            value={withdrawComment}
            onChange={(e) => setWithdrawComment(e.target.value)}
            variant="outlined"
            fullWidth
          />
          <Button variant="text" color="primary" onClick={handleWithdrawClose}>
            Never mind keep this task.
          </Button>
          <p>
            <b>Are you sure you want to withdraw?</b>
          </p>
          <p>This sends the requester and the admin a message about the change</p>
          <div className={classes.center}>
            <Button
              varitant="contained"
              className={cn(classes.warningButton, classes.button)}
              onClick={handleWithdrawComplete}
              disabled={!withdrawComment}
            >
              Withdraw
            </Button>
          </div>
        </div>
      )}
    </Dialog>
  )
}
