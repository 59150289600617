import React, { useState } from 'react'
import { Button, Grid } from '@material-ui/core'
import { stepOneSchema } from './schemas'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import PhoneField from '../../../components/PhoneField'

function StepOne(props) {
  const { onSubmit, classes } = props
  const [showEmail, setShowEmail] = useState(true)
  return (
    <Formik
      initialValues={{
        email: '',
        phoneNumber: '',
      }}
      validationSchema={stepOneSchema}
      onSubmit={(values) => onSubmit(stepOneSchema.cast(values))}
      validateOnBlur
    >
      {({ dirty, isValid, values, setFieldValue }) => {
        const switchContact = () => {
          setFieldValue('email', '')
          setFieldValue('phoneNumber', '')
          setShowEmail(!showEmail)
        }
        return (
          <Form>
            <Grid container spacing={2} direction="column" justify="center" alignItems="center">
              {showEmail && (
                <>
                  <div className={classes.title}>
                    <b>Email*</b>
                  </div>
                  <Field
                    name="email"
                    component={TextField}
                    className={classes.input}
                    label="Email address"
                    placeholder="Email address"
                    type="email"
                    variant="outlined"
                  />
                </>
              )}
              {!showEmail && (
                <>
                  <div className={classes.title}>
                    <b>Phone Number</b>
                  </div>
                  <Field
                    component={PhoneField}
                    name="phoneNumber"
                    variant="outlined"
                    className={classes.input}
                    placeholder="Phone Number"
                  />
                </>
              )}
              <Button
                className={classes.buttonLogin}
                variant="contained"
                disabled={!dirty || !isValid || (!values.phoneNumber && !values.email)}
                type="submit"
              >
                <div className={classes.loginText}>
                  <b>Reset Password</b>
                </div>
              </Button>
              <Button variant="text" onClick={switchContact} className={classes.link}>
                <div className={classes.changeContact} style={{ marginTop: '10px' }}>
                  <b>Reset Using {showEmail ? 'Phone Number' : 'Email'}</b>
                </div>
              </Button>
            </Grid>
          </Form>
        )
      }}
    </Formik>
  )
}

export default StepOne
