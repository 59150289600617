import React, { useState } from 'react'
import { Typography, Button, IconButton } from '@material-ui/core'
import AddTimeslotPrompt from './AddTimeslotPrompt'
import { DeleteOutline } from '@material-ui/icons'

export default function TimeSlotPicker({ arrayHelpers, maxSlots = 1 }) {
  // destructure values with the dynamic name of the Formik field from arrayHelpers.
  const {
    form: {
      values: { [arrayHelpers.name]: timeslots },
    },
    remove,
    push,
  } = arrayHelpers
  const [showPrompt, setShowPrompt] = useState(false)
  const slots = timeslots || []

  return (
    <div>
      <Typography variant="h6">What times are good to deliver this help?</Typography>
      <Typography variant="body1">
        Please add {maxSlots === 1 ? 'one' : `up to ${maxSlots.toString()} `}
        timeslots that will give the helpers a window of time to help out.
      </Typography>

      <Typography variant="body1">
        <b>Times Available</b>
      </Typography>
      {slots.map((slot, i) => (
        <div key={slot.startTime}>
          {/*  TODO: deal with tz's https://civicchamps.atlassian.net/browse/CCA-1420 */}
          {slot.startTime.format('MM/DD/YYYY hh:mma')} - {slot.endTime.format('hh:mma')}
          <IconButton aria-label="delete" onClick={() => remove(i)}>
            <DeleteOutline />
          </IconButton>
        </div>
      ))}

      <Button
        onClick={() => {
          setShowPrompt(true)
        }}
        variant="contained"
        color="primary"
      >
        Pick time slot(s)
      </Button>

      <AddTimeslotPrompt
        slots={slots}
        showing={showPrompt}
        remove={remove}
        push={push}
        maxSlots={maxSlots}
        closeModal={() => {
          setShowPrompt(false)
        }}
      />
    </div>
  )
}
