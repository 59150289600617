export const MembershipStatusCode = {
  Applicant: 'applicant',
  Declined: 'declined',
  ProbationaryMember: 'probationary member',
  Member: 'member',
  Removed: 'removed',
  Banned: 'banned',
}

export const friendlyVolunteerMembershipStatusCode = {
  [MembershipStatusCode.Applicant]: 'Application Under Review',
  [MembershipStatusCode.Declined]: 'Declined',
  [MembershipStatusCode.Banned]: 'Banned',
  [MembershipStatusCode.ProbationaryMember]: 'Probationary',
  [MembershipStatusCode.Member]: 'Approved',
  [MembershipStatusCode.Removed]: 'Removed',
}
