import React from 'react'
import { makeStyles, Button } from '@material-ui/core'
import { useHistory } from 'react-router'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    textAlign: 'center',
    marginTop: '20vh',
  },
  buttonLogin: {
    width: '285px',
    height: '61px',
    border: '1px solid black',
    borderWidth: '1px',
    marginTop: '20px',
    marginBottom: '45px',
  },
}))

const ChooseContactType = (props) => {
  const history = useHistory()
  if (props.loggedIn) {
    history.push('/receive-or-lend')
  }
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Button
        className={classes.buttonLogin}
        color="primary"
        variant="contained"
        onClick={() => history.push('/sign-up')}
      >
        <div>
          <b>Create Account</b>
        </div>
      </Button>
      <Button
        className={classes.buttonLogin}
        color="secondary"
        variant="contained"
        onClick={() => history.push('/sign-in')}
      >
        <div>
          <b>Login</b>
        </div>
      </Button>
    </div>
  )
}

export default ChooseContactType
