import React, { useState } from 'react'
import moment from 'moment'
import { Dialog, makeStyles, Button } from '@material-ui/core'
import cn from 'classnames'
import * as _ from 'lodash'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import CustomTimeslotPrompt from './CustomTimeslotPrompt'
import slotIsPast from '../../../../utils/slotIsPast'

const timeFormat = 'hh:mm a'
const generateTimeslots = (date) =>
  _.range(8, 18).map((i) => ({
    startTime: moment(date).hours(i).minutes(0).seconds(0),
    endTime: moment(date)
      .hours(i + 2)
      .minutes(0)
      .seconds(0),
  }))

const useStyles = makeStyles((theme) => ({
  modal: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  header: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    margin: theme.spacing(2),
  },
  timeSlot: {
    border: '1px solid',
    borderColor: '#707070',
    borderRadius: '2px',
    padding: theme.spacing(1),
    margin: theme.spacing(1),
    cursor: 'pointer',
  },
  inactive: {
    borderColor: theme.palette.neutral.lightGray,
    color: theme.palette.neutral.lightGray,
    cursor: 'not-allowed',
  },
  selected: {
    background: theme.palette.secondary.main,
    color: 'white',
    cursor: 'ponter',
  },
  date: {
    marginBottom: theme.spacing(2),
  },
  button: {
    margin: theme.spacing(1),
  },
}))

export default function AddTimeslotPrompt(props) {
  const { showing, slots, remove: onRemoveTimeslot, push: onAddTimeslot, maxSlots, closeModal } = props
  const [date, setDate] = useState(moment())
  const customPrompt = useShowPrompt(CustomTimeslotPrompt)
  const classes = useStyles()

  const getSlotIndex = (slot) => {
    return _.findIndex(slots, (s) => _.isEqual(s, slot))
  }
  const handleSlotClick = (slot) => {
    // do nothing if slot is in past
    if (slotIsPast(slot)) return
    const idx = getSlotIndex(slot)
    // remove already selected slot
    if (idx > -1) {
      onRemoveTimeslot(idx)
    } else if (slots.length < maxSlots) {
      onAddTimeslot(slot)
    }
  }
  const handleCustomClick = async () => {
    await customPrompt({ addSlot: handleSlotClick })
  }

  return (
    <Dialog open={showing} onClose={closeModal}>
      <div className={classes.modal}>
        <p className={classes.header}>What day?</p>
        <KeyboardDatePicker
          format="MM/DD/YYYY"
          minDate={moment()}
          value={date}
          onChange={setDate}
          inputVariant="outlined"
          className={classes.date}
        />
        {generateTimeslots(date).map((slot) => (
          <div
            key={slot.startTime.toString()}
            className={cn(
              classes.timeSlot,
              { [classes.inactive]: slots.length >= maxSlots || slotIsPast(slot) },
              { [classes.selected]: getSlotIndex(slot) > -1 },
            )}
            role="button"
            onClick={() => {
              handleSlotClick(slot)
            }}
          >
            {slot.startTime.format(timeFormat)} - {slot.endTime.format(timeFormat)}
          </div>
        ))}

        <div>
          <Button
            onClick={handleCustomClick}
            variant="contained"
            color="secondary"
            disabled={slots.length >= maxSlots}
            className={classes.button}
          >
            Custom Slot
          </Button>
        </div>

        <Button onClick={closeModal} variant="contained" color="primary" className={classes.button}>
          Done
        </Button>
      </div>
    </Dialog>
  )
}
