import { makeStyles } from '@material-ui/core'
import React from 'react'
import Content from '../components/Content'
import Loading from '../components/Loading'
import { isEmpty, sortBy } from 'lodash'
import { buttonClasses } from '../components/buttonClasses'
import {
  taskIsDone,
  taskIsCurrent,
  taskIsExpiring,
  taskIsScheduled,
  taskIsActive,
  taskIsCompleted,
  taskIsOverdue,
} from '../utils/taskUtils'
import LinkButton from '../components/LinkButton'
import useRequestedTasks from '../hooks/useRequestedTasks'
import { TaskCard } from './TaskCard'

const useStyles = makeStyles((theme) => ({
  ...buttonClasses(theme),
}))

export default function ReceiveDashboard() {
  const { requestedTasks, loading } = useRequestedTasks()
  const classes = useStyles()

  const orderedTasks = sortBy(requestedTasks, (task) => task.startTime)
  const { activeTasks, scheduledTasks, openTasks, overdueTasks } = orderedTasks.reduce(
    (acc, task) => {
      //  show any task until the feedback is given
      if (taskIsActive(task)) {
        acc.activeTasks.push(task)
      } else if (taskIsDone(task)) {
        return acc
      } else if (taskIsScheduled(task)) {
        acc.scheduledTasks.push(task)
      } else if (taskIsCurrent(task)) {
        //  not yet assigned
        acc.openTasks.push(task)
      } else if (taskIsOverdue(task)) {
        acc.overdueTasks.push(task)
      }
      return acc
    },
    { activeTasks: [], scheduledTasks: [], openTasks: [], overdueTasks: [] },
  )

  return (
    <Content>
      <h1 style={{ textAlign: 'center' }}>Dashboard</h1>
      {loading ? (
        <Loading />
      ) : (
        <>
          <h2>Active</h2>
          {isEmpty(activeTasks) ? (
            <p>You do not have any active tasks</p>
          ) : (
            activeTasks.map((task) => (
              <TaskCard
                key={task.id}
                task={task}
                showStatus
                active
                view="recipient"
                requiresFeedback={taskIsCompleted(task) && !task.recipientFeedback}
              />
            ))
          )}
          <h2>Scheduled Requests</h2>
          {isEmpty(scheduledTasks) ? (
            <p>You do not have any scheduled tasks today</p>
          ) : (
            scheduledTasks.map((task) => <TaskCard key={task.id} task={task} showStatus scheduled view="recipient" />)
          )}
          <h2>Open Requests</h2>
          {isEmpty(openTasks) ? (
            <p>You do not have any open tasks today</p>
          ) : (
            openTasks.map((task) => (
              <TaskCard key={task.id} task={task} isExpiring={taskIsExpiring(task)} showStatus view="recipient" />
            ))
          )}
          {!isEmpty(overdueTasks) && (
            <>
              <h2>Overdue Requests</h2>
              {overdueTasks.map((task) => (
                <TaskCard key={task.id} task={task} isOverdue showStatus view="recipient" />
              ))}
            </>
          )}
        </>
      )}
      <LinkButton to="/request-task" text="Make a Request" className={classes.button} variant="secondary" fullWidth />
    </Content>
  )
}
