import { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from '../api/hooks'

const useGroupMemberships = ({ groupType, personId }) => {
  const [request, { result, loading, called }] = useFetch('Error fetching organizations')
  const getMemberships = useCallback(async () => {
    return request({
      method: 'get',
      url: `/required-group/${groupType}/members/${personId}`,
    })
  }, [request, groupType, personId])

  useEffect(() => {
    getMemberships()
  }, [getMemberships])

  return useMemo(
    () => ({
      memberships: result || [],
      loading: loading || !called,
    }),
    [result, loading, called],
  )
}

export default useGroupMemberships
