import React from 'react'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  content: {
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(2),
  },
}))

export default function Content(props) {
  const { children } = props
  const classes = useStyles()

  return <div className={classes.content}>{children}</div>
}
