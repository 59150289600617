import React from 'react'
import { FieldArray } from 'formik'
import TimeSlotPicker from './TimeSlotPicker'
import * as _ from 'lodash'
import StepContainer from '../StepContainer'

export default function ChooseTimeSlots(props) {
  const { nextStep, value } = props

  return (
    <StepContainer
      header="Preferred Service Times"
      subHeader="Give us any additional details about the task"
      disabled={_.isEmpty(value)}
      nextStep={nextStep}
    >
      <FieldArray
        name="timeslots"
        render={(arrayHelpers) => <TimeSlotPicker arrayHelpers={arrayHelpers} maxSlots={3} />}
      />
    </StepContainer>
  )
}
