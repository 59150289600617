import React from 'react'
import * as moment from 'moment-timezone'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { makeStyles } from '@material-ui/core'
import { buttonClasses } from '../components/buttonClasses'
import { TaskStatusDisplayNames } from '../utils/taskUtils'

const useStyles = makeStyles((theme) => ({
  ...buttonClasses(theme),
  task: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(2),
    textAlign: 'center',
    margin: theme.spacing(2),
    cursor: 'pointer',
    // TODO: confirm hover colors for all the styles
    '&:hover': {
      backgroundColor: theme.palette.neutral.white,
      color: theme.palette.secondary.main,
    },
  },
  active: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.neutral.white,
  },
  scheduled: {
    backgroundColor: theme.palette.accent.lightBlue.main,
    color: theme.palette.neutral.darkGray,
  },
  other: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.neutral.black,
  },
  expiring: {
    border: '2px solid #FF6F3E',
  },
  isExpiring: {
    color: '#FF6F3E',
    fontWeight: 'bold',
  },
}))

export const TaskCard = ({ task, showStatus, isExpiring, active, scheduled, requiresFeedback, isOverdue, view }) => {
  const history = useHistory()
  const classes = useStyles()
  const className = cn({
    [classes.expiring]: isExpiring || isOverdue,
    [classes.active]: active,
    [classes.scheduled]: scheduled,
    [classes.task]: true,
    [classes.other]: !active && !scheduled,
  })
  const timeZone = task.organization.timeZone || 'America/New_York'
  return (
    <div
      className={className}
      onClick={() => {
        history.push(`/tasks/${task.id}?view=${view}`)
      }}
      key={task.id}
    >
      <p>
        <b>
          {task.volunteeringRole.name} for {task.recipient.givenName} {task.recipient.familyName}
        </b>
      </p>
      {task.startTime && task.endTime && (
        <p>
          {moment(task.startTime).tz(timeZone).format('MM/DD/YY hh:mma')} -{' '}
          {moment(task.endTime).tz(timeZone).format('hh:mma')}
        </p>
      )}
      {showStatus && <p>{TaskStatusDisplayNames[task.taskStatus]}</p>}
      {isExpiring && <p className={classes.isExpiring}>Expiring Soon</p>}
      {isOverdue && <p className={classes.isExpiring}>Overdue</p>}
      {requiresFeedback && <p>Please enter feedback</p>}
    </div>
  )
}
