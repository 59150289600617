import { useCallback } from 'react'
import { useFetch } from '../api/hooks'

const useCreateRecipient = () => {
  const [request, status] = useFetch('Error creating recipient')
  const createRecipient = useCallback(
    (personPayload) => {
      return request({
        method: 'post',
        url: '/user/task-recipients',
        config: {
          body: personPayload,
        },
      })
    },
    [request],
  )

  return [createRecipient, status]
}

export default useCreateRecipient
