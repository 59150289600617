import { Button, makeStyles } from '@material-ui/core'
import PersonAdd from '@material-ui/icons/PersonAdd'
import React, { useState } from 'react'
import usePersonLocations from '../../../../hooks/usePersonLocations'
import { getDisplayAddressFromEvent } from 'civic-champs-shared/core/location/utils'
import LocationForm from './LocationForm'
import useCreatePersonLocation from '../../../../hooks/useCreatePersonLocation'
import * as _ from 'lodash'
import RadioSelect from '../../../../components/RadioSelect'
import StepContainer from '../StepContainer'

const useStyles = makeStyles((theme) => ({
  addNew: {
    marginBottom: '30px',
    marginTop: '30px',
    alignSelf: 'center',
  },
}))

export default function ChooseLocation(props) {
  const { nextStep, value, recipient, onChange } = props
  const { personLocations, loading } = usePersonLocations(recipient.id)
  const [createPersonLocation, { loading: creatingNewLocation }] = useCreatePersonLocation()
  const [showLocationSelect, setShowLocationSelect] = useState(false)
  const classes = useStyles()
  const createNewLocation = async (location) => {
    const personLocation = await createPersonLocation(recipient.id, location)
    onChange(personLocation)
    nextStep()
  }

  const showLocationForm = showLocationSelect || !personLocations.length

  const content = showLocationForm ? (
    <LocationForm onSubmit={createNewLocation} loading={creatingNewLocation} />
  ) : (
    <>
      <RadioSelect
        options={personLocations}
        value={value}
        isChecked={(option, value) => String(_.get(value, 'id')) === String(option.location.id)}
        onChange={onChange}
        getLabel={(personLocation) => getDisplayAddressFromEvent(personLocation.location.address, personLocation.label)}
      />
      <Button
        color="secondary"
        className={classes.addNew}
        onClick={() => setShowLocationSelect(true)}
        startIcon={<PersonAdd style={{ fontSize: 26 }} />}
      >
        <div>
          <u>
            <b>New Address</b>
          </u>
        </div>
      </Button>
    </>
  )

  return (
    <StepContainer
      header="Recipient Address"
      disabled={!value.id}
      nextStep={showLocationForm ? undefined : nextStep}
      loading={loading}
    >
      {content}
    </StepContainer>
  )
}
