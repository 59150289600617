import React from 'react'
import useUserStatus from './useUserStatus'

export const UserContext = React.createContext({ user: null, loading: false, loggedIn: false })

export const UserContextProvider = (props) => {
  const { children } = props
  const status = useUserStatus()
  return <UserContext.Provider value={status}>{children}</UserContext.Provider>
}
