import { useCallback, useEffect, useMemo } from 'react'
import { useFetch } from '../api/hooks'

const useCustomMessage = (uuid, recipientId) => {
  const [request, { result, loading, called, error }] = useFetch('Error fetching message')
  const getMessage = useCallback(async () => {
    return request({
      method: 'get',
      url: `/notification-blasts/${uuid}/recipient/${recipientId}`,
      public: true,
    })
  }, [request, uuid, recipientId])

  useEffect(() => {
    getMessage()
  }, [getMessage])

  return useMemo(
    () => ({
      message: result || null,
      loading: loading || !called,
      error,
    }),
    [result, loading, called],
  )
}

export default useCustomMessage
