import { useCallback, useEffect } from 'react'
import { useFetch } from '../api/hooks'

const useOrgDetailsById = (orgId) => {
  const [request, { result, loading, called }] = useFetch('Error fetching organization')

  const getOrgDetails = useCallback(() => {
    return request({
      method: 'get',
      url: `/task_organizations/${orgId}`,
    })
  }, [orgId, request])

  useEffect(() => {
    getOrgDetails()
  }, [getOrgDetails])

  return {
    orgDetails: result || {},
    loading: loading || !called,
    refresh: getOrgDetails,
  }
}

export default useOrgDetailsById
