import React, { useState, useMemo } from 'react'
import Auth from '@aws-amplify/auth'
import { makeStyles, Button } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-material-ui'
import yup from '../../utils/yup'
import { useHistory, Link } from 'react-router-dom'
import { useErrorNotification } from '../../api/hooks'
import Loading from '../../components/Loading'
import PhoneField from '../../components/PhoneField'
import { ArrowBack } from '@material-ui/icons'
import PasswordField from '../../components/PasswordField'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    textAlign: 'center',
  },
  container: {
    maxWidth: '80vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonLogin: {
    width: '285px',
    height: '61px',
    backgroundColor: '#5C8DE8FA',
    borderColor: '#3D3D3D',
    borderWidth: '1px',
    marginTop: '20px',
    marginBottom: '45px',
  },
  loginText: {
    color: 'white',
  },
  input: {
    width: '285px',
    marginBottom: '31px',
    borderColor: '#707070',
  },
  error: {
    color: 'red',
    fontSize: '11px',
    width: '285px',
    marginRight: '-20px',
    marginTop: '5px',
  },
  title: {
    fontSize: '21px',
    marginBottom: '8px',
    width: '285px',
    marginTop: '16px',
  },
  forgotText: {
    color: '#FF6F3E',
    fontSize: '17px',
    alignSelf: 'center',
  },
  link: {
    textDecoration: 'solid #FF6F3E',
  },
  changeContact: {
    color: '#5C8DE8FA',
    fontSize: '16px',
    alignSelf: 'center',
  },
  backLink: {
    color: theme.palette.secondary.main,
    marginLeft: '25px',
    marginTop: '25px',
    width: '100%',
    textAlign: 'start',
  },
}))

const SignIn = () => {
  const [showEmail, setShowEmail] = useState(true)
  const classes = useStyles()
  const history = useHistory()
  const showError = useErrorNotification()

  const onSignIn = async (values) => {
    const { email, phoneNumber, password } = values
    try {
      await Auth.signIn(email || phoneNumber, password)
      history.push('/receive-or-lend')
    } catch (err) {
      showError('There was a problem signing in', err)
    }
  }

  const formSchema = useMemo(
    () =>
      yup
        .object({
          password: yup.string().min(8).required('Password is required').label('Password'),
        })
        .concat(
          showEmail
            ? yup.object({ email: yup.string().email().trim().lowercase().required('Required Field').label('Email') })
            : yup.object({ phoneNumber: yup.string().phoneNumber().required('Required Field').label('Phone Number') }),
        ),
    [showEmail],
  )

  return (
    <div className={classes.main}>
      <Link to="/login" className={classes.backLink}>
        <ArrowBack />
      </Link>
      <Formik
        initialValues={{ email: '', phoneNumber: '', password: '' }}
        validationSchema={formSchema}
        onSubmit={(values) => onSignIn(formSchema.cast(values))}
      >
        {({ isValid, dirty, handleSubmit, isSubmitting, setFieldValue }) => {
          const switchContact = () => {
            setFieldValue('email', '')
            setFieldValue('phoneNumber', '')
            setShowEmail(!showEmail)
          }
          return (
            <Form onSubmit={handleSubmit}>
              {isSubmitting ? (
                <Loading />
              ) : (
                <div className={classes.container}>
                  {showEmail && (
                    <>
                      <div className={classes.title}>
                        <b>Email*</b>
                      </div>
                      <Field
                        name="email"
                        component={TextField}
                        className={classes.input}
                        placeholder="Email address"
                        type="email"
                        variant="outlined"
                      />
                    </>
                  )}
                  {!showEmail && (
                    <>
                      <div className={classes.title}>
                        <b>Mobile Number*</b>
                      </div>
                      <Field
                        component={PhoneField}
                        name="phoneNumber"
                        variant="outlined"
                        className={classes.input}
                        placeholder="Phone Number"
                      />
                    </>
                  )}
                  <div className={classes.title}>
                    <b>Password*</b>
                  </div>
                  <Field
                    component={PasswordField}
                    type="password"
                    name="password"
                    className={classes.input}
                    placeholder="Password"
                    variant="outlined"
                    hideErrorUntilTouched
                  />
                  <Button
                    className={classes.buttonLogin}
                    variant="contained"
                    disabled={!dirty || !isValid}
                    type="submit"
                  >
                    <div className={classes.loginText}>
                      <b>Login</b>
                    </div>
                  </Button>
                  <Link to={'/reset-password'} className={classes.link}>
                    <div className={classes.forgotText}>
                      <b>
                        <u>Forgot Password</u>
                      </b>
                    </div>
                  </Link>
                  <Button variant="text" onClick={switchContact} className={classes.link}>
                    <div className={classes.changeContact} style={{ marginTop: '10px' }}>
                      <b>{showEmail ? 'Mobile' : 'Email'} Login</b>
                    </div>
                  </Button>
                </div>
              )}
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

export default SignIn
