import React, { BaseSyntheticEvent } from 'react'
import './index.scss'
import classNames from 'classnames'

interface OptionData {
  value: string
  label: string
}

interface Props {
  checked?: boolean
  selectedValue?: any
  id: string
  label?: string
  value?: string
  onChange: (value: any) => void
  name: string
  className?: string
  dataOption?: OptionData[]
  onBlur?: (value: BaseSyntheticEvent) => void
}

const RadioButtonComponent = (props: Props) => {
  const { id, name, value, label, onChange, className, selectedValue, onBlur } = props
  const classes = classNames('radio_button_item', {
    [`${className}`]: className,
  })
  const checkedValue = selectedValue === value
  return (
    <div className="radio_button_wrapper">
      <div className={classes}>
        <span className={`radio_button_checkmark ${checkedValue ? 'radio_button_checked' : ''}`} onClick={onChange} />
        <input
          id={id}
          type="radio"
          name={name}
          value={value}
          onChange={onChange}
          checked={checkedValue}
          onBlur={onBlur}
        />
        <label htmlFor={id}>{label}</label>
      </div>
    </div>
  )
}

export default RadioButtonComponent
