import React from 'react'
import cn from 'classnames'
import {Button} from '@material-ui/core'
import './index.scss'

interface SubmitButtonProps {
    onClick: () => void,
    disabled: boolean,
    className?: string,
    title?: string,
    children?: any
}

const SubmitButton = ({ onClick, disabled, className, title, children }: SubmitButtonProps) => {
    const classes = cn('submit-button', { disabled }, className )

    return (
        <Button onClick={onClick} className={classes} disabled={disabled}>
            {children ? children : title ? title : 'SUBMIT'}
        </Button>
    )
}

export default SubmitButton
