import { useFetch } from '../api/hooks'
import { useCallback, useEffect, useState } from 'react'

const AVAILABLE_STATUSES = ['accepted']
const COMPLETED_STATUSES = ['completed', 'canceled']

const useTasks = () => {
  const [request, { result, loading, called }] = useFetch('Error fetching tasks')
  const [tasks, setTasks] = useState([])

  const [completedTasks, setCompletedTasks] = useState([])
  const [availableTasks, setAvailableTasks] = useState([])

  const getTasks = useCallback(async () => {
    return request({
      method: 'get',
      url: `/user/available-tasks`,
    })
  }, [request])

  useEffect(() => {
    getTasks()
  }, [getTasks])

  useEffect(() => {
    if (result) {
      setAvailableTasks(result.filter((r) => AVAILABLE_STATUSES.includes(r.taskStatus)))
      setTasks(result.filter((r) => !COMPLETED_STATUSES.includes(r.taskStatus)))
      setCompletedTasks(result.filter((r) => COMPLETED_STATUSES.includes(r.taskStatus)))
    }
  }, [result])

  return {
    tasks,
    completedTasks,
    availableTasks,
    loading: loading || !called,
    refresh: getTasks,
  }
}

export default useTasks
