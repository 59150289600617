import React from 'react'
import CenterContent from '../components/CenterContent'
import LinkButton from '../components/LinkButton'

function ReceiveOrLend() {
  return (
    <CenterContent>
      <LinkButton 
        to="/receive-dashboard" 
        text="Need a Hand" 
        subtext="Request Help/Services" 
      />
      <LinkButton 
        to="/lend-dashboard" 
        variant="secondary" 
        text="Lend a Hand" 
        subtext="Volunteer to Help"
      />
    </CenterContent>
  )
}
export default ReceiveOrLend
