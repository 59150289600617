import React, { useState } from 'react'
import useCreateRecipient from '../../../../hooks/useCreateRecipient'
import ChooseRelationship from '../ChooseRelationship'
import { RecipientForm } from './RecipientForm'
import { IconButton } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'

export const CreateRecipient = (props) => {
  const { onSubmit, prevStep } = props
  const [createRecipient, { loading }] = useCreateRecipient()
  const [recipient, setRecipient] = useState(null)
  const [showChooseRelationship, setShowChooseRelationship] = useState(false)

  const submitRelationship = async (relationship) => {
    const person = await createRecipient({ ...recipient, relationship })
    onSubmit(person)
  }

  const submitRecipient = (val) => {
    setRecipient(val)
    setShowChooseRelationship(true)
  }

  const goBack = () => {
    return showChooseRelationship ? setShowChooseRelationship(false) : prevStep()
  }

  return (
    <>
      <IconButton onClick={goBack}>
        <ArrowBack />
      </IconButton>
      {showChooseRelationship ? (
        <ChooseRelationship onSubmit={submitRelationship} loading={loading} />
      ) : (
        <RecipientForm initialValues={recipient} onSubmit={submitRecipient} />
      )}
    </>
  )
}
