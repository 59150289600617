import yup from '../../../utils/yup'

export const stepOneSchema = (emailIsPrimary) =>
  yup
    .object({
      password: yup.string().min(8).label('Password').required('Password is required'),
      passwordConfirm: yup
        .string()
        .label('Confirm Password')
        .oneOf([yup.ref('password'), null], 'Password does not match')
        .required('Retype Password is required'),
    })
    .concat(emailIsPrimary ? requiredEmailSchema : requiredPhoneSchema)

const emailString = yup.string().email().trim().lowercase().nullable()
const phoneString = yup.string().phoneNumber().label('Phone Number').nullable()

const nullableEmailSchema = yup.object({
  email: emailString,
})

const nullablePhoneSchema = yup.object({
  phoneNumber: phoneString,
})

const requiredEmailSchema = yup.object({
  email: emailString.required(),
})

const requiredPhoneSchema = yup.object({
  phoneNumber: phoneString.required(),
})

export const stepTwoSchema = (emailIsPrimary) =>
  yup
    .object({
      givenName: yup.string().name().label('first name').required('First Name is required'),
      familyName: yup.string().name().label('last name').required('Last Name is required'),
      olderThanThirteen: yup.bool().oneOf([true], 'Users must be thirteen years of age or older'),
      birthday: yup
        .string()
        .matches(/^(((0)[0-9])|((1)[0-2]))(\/)([0-2][0-9]|(3)[0-1])(\/)\d{4}$/, '${path} must be MM/DD/YYYY')
        .nullable()
        .notRequired(),
    })
    .concat(emailIsPrimary ? nullablePhoneSchema : nullableEmailSchema)

export const stepThreeSchema = yup.object({
  code: yup.string().required('Code is required'),
  acceptsCharges: yup
    .bool()
    .oneOf([true], 'Users must accept charges')
    .required('User must accept charges to continue'),
})
