import { makeStyles } from '@material-ui/core'
import React from 'react'
import Content from '../components/Content'
import Loading from '../components/Loading'
import useTasks from '../hooks/useTasks'
import moment from 'moment'
import Today from '@material-ui/icons/Today'
import { useHistory } from 'react-router'
import { taskIsCurrent } from '../utils/taskUtils'
import { ArrowBack, Room } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import LinkButton from '../components/LinkButton'
import { buttonClasses } from '../components/buttonClasses'

const useStyles = makeStyles((theme) => ({
  ...buttonClasses(theme),
  task: {
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    margin: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    cursor: 'pointer',
  },
  taskPanel: {
    width: '33.33%',
    textAlign: 'center',
  },
  icon: {
    color: theme.palette.secondary.main,
  },
  backLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
}))

export default function AvailableTasks() {
  const classes = useStyles()
  const history = useHistory()

  const { availableTasks, loading } = useTasks()

  const handleTaskClick = (id) => {
    history.push(`/tasks/${id}?view=volunteer`)
  }

  const tasksToShow = availableTasks.filter(taskIsCurrent)
  return (
    <Content>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Link to="/lend-dashboard" className={classes.backLink}>
            <ArrowBack />
            Back to Dashboard
          </Link>
          <h1 style={{ textAlign: 'center' }}>Available Tasks</h1>
          {tasksToShow.length === 0 && <p>There are currently no tasks available.</p>}
          {tasksToShow.map((task) => (
            <div key={task.id} className={classes.task} onClick={() => handleTaskClick(task.id)}>
              <div className={classes.taskPanel}>
                <Today className={classes.icon} />
                <p>{moment(task.overdueAt).format('MM/DD/YY')}</p>
              </div>
              <div className={classes.taskPanel}>
                <p>
                  <b>{task.volunteeringRole.name}</b>
                </p>
                <p>for</p>
                <p>
                  {task.recipient.givenName} {task.recipient.familyName}
                </p>
              </div>
              {task.location && (
                <div className={classes.taskPanel}>
                  <Room className={classes.icon} />
                  <p>{task.location.address.city}</p>
                </div>
              )}
            </div>
          ))}
          <LinkButton
            to="/organizations"
            text="APPLY TO ORGANIZATIONS"
            className={classes.button}
            variant="secondary"
            fullWidth
          />
        </>
      )}
    </Content>
  )
}
