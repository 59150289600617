import React from 'react'
import { makeStyles, Button, Typography } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    overflowY: 'auto',
    textAlign: 'center',
  },
  buttonLogin: {
    width: '285px',
    height: '61px',
    border: '1px solid black',
    borderWidth: '1px',
    marginTop: '20px',
    marginBottom: '45px',
  },
}))

const CreateAccount = ({ selectIsEmail }) => {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      <Typography variant="h4">Create Account</Typography>
      <Typography variant="subtitle1">Please select the username you would like to use</Typography>
      <Button className={classes.buttonLogin} color="primary" variant="contained" onClick={() => selectIsEmail(false)}>
        <div>
          <b>Mobile</b>
        </div>
      </Button>
      <Button className={classes.buttonLogin} color="secondary" variant="contained" onClick={() => selectIsEmail(true)}>
        <div>
          <b>Email</b>
        </div>
      </Button>
    </div>
  )
}

export default CreateAccount
