import React from 'react'
import { Grid, makeStyles } from '@material-ui/core'
import { TextField } from 'formik-material-ui'
import { Field, Formik } from 'formik'
import yup from '../../../../utils/yup'
import PhoneField from '../../../../components/PhoneField'
import NextButton from '../../../../components/NextButton'
import CheckboxField from '../../../../components/CheckboxField'
import StepContainer from '../StepContainer'

const personSchema = yup
  .object({
    givenName: yup.string().trim().required('required'),
    familyName: yup.string().trim().required('required'),
    phoneNumber: yup.string().phoneNumber().nullable(),
    email: yup.string().trim().lowercase().email().nullable(),
    hasNoContact: yup.boolean().default(false),
  })
  .atLeastOneOf(['email', 'phoneNumber', 'hasNoContact'])

const useStyles = makeStyles(() => ({
  subTitle: {
    fontSize: '21px',
    marginBottom: '8px',
    width: '324px',
    marginTop: '21px',
  },
  input: {
    width: '324px',
    borderColor: '#707070',
  },
}))

const emptyRecipient = {
  givenName: '',
  familyName: '',
  email: '',
  phoneNumber: '',
}

export const RecipientForm = (props) => {
  const { onSubmit, initialValues } = props
  const classes = useStyles()

  const submit = (values) => {
    if (values.hasNoContact) {
      // just clearing out phoneNumber and email if hasNoContact is selected
      onSubmit({ ...values, email: null, phoneNumber: null })
    } else {
      return onSubmit(values)
    }
  }

  return (
    <StepContainer
      header="Recipient Contact Info"
      subHeader="Please tell us who should receive the help and how to contact them"
    >
      <Formik
        initialValues={initialValues || emptyRecipient}
        onSubmit={(values) => submit(personSchema.cast(values))}
        validationSchema={personSchema}
        validateOnBlur={true}
      >
        {({ handleSubmit, isSubmitting, isValid, values }) => {
          // TODO: not sure why 'atLeastOneOf' is not being respected by formik, as it is returning 'false' in test
          const disabled = !isValid || isSubmitting || (!values.phoneNumber && !values.email && !values.hasNoContact)
          //  not using <form> so that it's not in a nested form
          return (
            <Grid container spacing={2} direction="column">
              <Grid item>
                <div className={classes.subTitle}>
                  <b>First Name*</b>
                </div>
                <Field
                  component={TextField}
                  className={classes.input}
                  id="givenName"
                  name="givenName"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <div className={classes.subTitle}>
                  <b>Last Name*</b>
                </div>
                <Field
                  component={TextField}
                  className={classes.input}
                  id="familyName"
                  name="familyName"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <div className={classes.subTitle}>
                  <b>Phone Number</b>
                </div>
                <Field
                  component={PhoneField}
                  disabled={values.hasNoContact}
                  className={classes.input}
                  name="phoneNumber"
                  variant="outlined"
                  fullWidth
                />
              </Grid>
              <Grid item>
                <div className={classes.subTitle}>
                  <b>Email</b>
                </div>
                <Field
                  fullWidth
                  disabled={values.hasNoContact}
                  variant="outlined"
                  className={classes.input}
                  component={TextField}
                  name="email"
                />
              </Grid>
              <Grid item>
                <Field
                  component={CheckboxField}
                  name="hasNoContact"
                  label="The recipient does not have a cell phone or email"
                  labelstyle={{ color: '#FF6F3E', font: 'normal normal normal 18px/24px Open Sans' }}
                />
              </Grid>
              <Grid item>
                <NextButton disabled={disabled} type="submit" onClick={handleSubmit} />
              </Grid>
            </Grid>
          )
        }}
      </Formik>
    </StepContainer>
  )
}
