import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField'
import './index.scss'

type RefCallback<E extends Element = Element> = (elem: E) => void

export interface Props {
  value?: string
  onChange?: (e: string) => void
  onBlur?: (e: string) => void
  hasError?: boolean
  autoCapitalize?: string
  disabled?: boolean
  required?: boolean
  isTextarea?: boolean
  withoutLabel?: boolean
  placeholder?: string
  type?: string
  rows?: string
  rowsMax?: string
  label?: string
  errorMessage?: string
  id?: string
  inputRef?: React.MutableRefObject<HTMLInputElement | undefined> | RefCallback<HTMLInputElement>
  onKeyDown?: (event: any) => void
}

export default function InputComponent(props: Props) {
  const {
    value,
    onChange,
    hasError,
    disabled,
    required,
    isTextarea,
    withoutLabel,
    type = 'text',
    rows = '3',
    rowsMax = '3',
    label = 'Label',
    errorMessage = 'Required field',
    placeholder,
    id,
    onKeyDown,
    onBlur,
    inputRef,
    autoCapitalize,
  } = props

  const handleChange = (e: any) => onChange && onChange(e.target.value)

  const handleBlur = (e: any) => {
    onBlur && onBlur(e.target.value)
  }

  useEffect(() => {
    onBlur && value && onBlur(value)
  }, [value, onBlur])

  return (
    <TextField
      inputRef={inputRef}
      id={id}
      fullWidth
      autoCapitalize={autoCapitalize || 'none'}
      multiline={isTextarea}
      disabled={disabled}
      required={required}
      classes={{ root: 'custom-input-component' }}
      label={!withoutLabel ? label : ''}
      value={value || ''}
      rowsMax={rowsMax}
      rows={rows}
      error={hasError}
      placeholder={placeholder}
      helperText={hasError && errorMessage}
      variant="outlined"
      onChange={handleChange}
      onBlur={handleBlur}
      type={type}
      onKeyDown={onKeyDown}
    />
  )
}
