import React, { useState } from 'react'
import Loading from '../../../components/Loading'
import StepOne from './StepOne'
import StepTwo from './StepTwo'
import StepThree from './StepThree'
import { Auth } from 'aws-amplify'
import { Link, useHistory } from 'react-router-dom'
import { useErrorNotification } from '../../../api/hooks'
import { v4 as uuidv4 } from 'uuid'
import { makeStyles } from '@material-ui/core'
import { useShowPrompt } from 'civic-champs-shared/core/modal/hooks'
import RequirementsModal from 'civic-champs-shared/question-sets/components/RequirementsModal'
import { PrerequisitesAction } from 'civic-champs-shared/question-sets/types'
import { ArrowBack } from '@material-ui/icons'
import ChooseContactType from './ChooseContactType'

const useStyles = makeStyles((theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  container: {
    maxWidth: '80vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonLogin: {
    width: '287px',
    height: '69px',
    backgroundColor: '#FFCD00',
    borderColor: '#3D3D3D',
    borderWidth: '1px',
    marginTop: '35px',
    marginBottom: '10px',
  },
  loginText: {
    color: '#3D3D3D',
  },
  input: {
    width: '285px',
    borderColor: '#707070',
  },
  inputPhone: {
    width: '271px',
    height: '50px',
    paddingLeft: '10px',
    borderWidth: '0.5px',
    borderColor: '#CCCCCC',
    borderRadius: '2px',
  },
  inputErrorPhone: {
    width: '271px',
    height: '50px',
    paddingLeft: '10px',
    borderColor: 'red',
    borderWidth: '0.5px',
    borderRadius: '2px',
  },
  error: {
    color: 'red',
    fontSize: '11px',
    width: '285px',
    marginRight: '-20px',
    marginTop: '5px',
  },
  title: {
    fontSize: '21px',
    marginBottom: '8px',
    width: '285px',
    marginTop: '21px',
  },
  backLink: {
    color: theme.palette.secondary.main,
    marginLeft: '25px',
    marginTop: '25px',
    width: '100%',
  },
}))

export default function SignUp() {
  const [step, setStep] = useState(0)
  const [emailIsPrimary, setEmailIsPrimary] = useState(true)
  const [userUuid, setUuid] = useState('')
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const showError = useErrorNotification()
  const classes = useStyles()
  const fulfillRequirementsPrompt = useShowPrompt(RequirementsModal)

  const selectIsEmail = (bool) => {
    setEmailIsPrimary(bool)
    setStep(1)
  }

  const handleRequirements = async () => {
    try {
      return await fulfillRequirementsPrompt({
        filters: {
          action: PrerequisitesAction.USER_SIGN_UP,
          freshAnswerSet: false,
          excludeCompletedAnswerSets: true,
        },
        continueEvenIfError: true,
      })
    } catch (error) {
      return false
    }
  }

  const [profile, setProfile] = useState({
    givenName: '',
    familyName: '',
    email: '',
    phoneNumber: '',
    birthday: null,
    password: '',
    olderThanThirteen: false,
  })

  const updateProfile = (values) => {
    const updatedProfile = { ...profile, ...values }
    setProfile(updatedProfile)
    return updatedProfile
  }

  const onSubmitStepOne = async (values) => {
    updateProfile(values)
    setStep(2)
  }

  const onSubmitStepTwo = async (values) => {
    const updatedProfile = updateProfile(values)
    await signUp(updatedProfile)
  }

  const signUp = async (updatedProfile) => {
    const uuid = uuidv4()
    const { givenName, familyName, email, phoneNumber, password, birthday } = updatedProfile
    const phoneNumberKey = emailIsPrimary ? 'custom:alt_phone_number' : 'phone_number'
    const request = {
      username: uuid,
      password,
      attributes: {
        ...(email ? { email } : {}),
        ...(phoneNumber ? { [phoneNumberKey]: phoneNumber } : {}),
        // TODO: set up cognito to handle birthdays
        // ...(birthday ? { birthday } : {}),
        given_name: givenName,
        family_name: familyName,
      },
    }
    try {
      setLoading(true)
      await Auth.signUp(request)
      setStep(3)
      setUuid(uuid)
    } catch (err) {
      showError('There was a problem creating your account', err)
    } finally {
      setLoading(false)
    }
  }

  const onSubmitStepThree = async ({ code }) => {
    try {
      setLoading(true)
      await Auth.confirmSignUp(userUuid, code)
      await Auth.signIn(userUuid, profile.password)
      await handleRequirements()
      history.push('/receive-or-lend')
    } catch (err) {
      showError('There was a problem confirming your account', err)
    } finally {
      setLoading(false)
    }
  }

  if (loading) {
    return <Loading />
  }

  return (
    <div className={classes.main}>
      <Link to="/login" className={classes.backLink}>
        <ArrowBack />
      </Link>
      <div className={classes.container}>
        {step === 0 && <ChooseContactType selectIsEmail={selectIsEmail} />}
        {step === 1 && (
          <StepOne
            onBack={() => setStep(0)}
            profile={profile}
            emailIsPrimary={emailIsPrimary}
            onSubmit={onSubmitStepOne}
            classes={classes}
          />
        )}
        {step === 2 && (
          <StepTwo
            emailIsPrimary={emailIsPrimary}
            onChange={updateProfile}
            onBack={() => setStep(1)}
            profile={profile}
            onSubmit={onSubmitStepTwo}
            classes={classes}
          />
        )}
        {step === 3 && (
          <StepThree username={userUuid} onSubmit={onSubmitStepThree} classes={classes} profile={profile} />
        )}
      </div>
    </div>
  )
}
