import React from 'react'
import NextButton from '../../../components/NextButton'
import Loading from '../../../components/Loading'
import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  header: {
    textAlign: 'center',
  },
  subHeader: {
    fontSize: '18px',
    marginBottom: '40px',
    textAlign: 'center',
    fontWeight: 'normal',
  },
  row: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'center',
    marginLeft: '0',
  },
}))

interface Props {
  nextStep?: () => void
  header: string
  subHeader?: string
  disabled: boolean
  children?: React.ReactNode
  loading?: boolean
}

export default function StepContainer(props: Props) {
  const { nextStep, header, subHeader, disabled, children, loading } = props
  const classes = useStyles()
  return (
    <>
      <h1 className={classes.header}>{header}</h1>
      {subHeader && <h2 className={classes.subHeader}>{subHeader}</h2>}
      <div className={classes.row}>
        {loading && <Loading />}
        {!loading && children}
        {nextStep && <NextButton disabled={disabled} onClick={nextStep} />}
      </div>
    </>
  )
}
