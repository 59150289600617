import { Button, Grid, makeStyles } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import Content from '../components/Content'
import Loading from '../components/Loading'
import useVolunteerAvailability from '../hooks/useVolunteerAvailability'
import cn from 'classnames'
import { keyBy } from 'lodash'
import useSaveAvailability from '../hooks/useSaveAvailability'
import { useHistory } from 'react-router'

const useStyles = makeStyles((theme) => ({
  backLink: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.secondary.main,
  },
  label: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  option: {
    border: '1px solid',
    borderRadius: '2px',
    borderColor: theme.palette.secondary.main,
    padding: theme.spacing(1),
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  selected: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.neutral.white,
  },
}))

const DAYS_OF_WEEK = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const INITIAL_STATE = keyBy(DAYS_OF_WEEK)

export default function UserPreferences(props) {
  const { volunteerAvailability, loading: getLoading } = useVolunteerAvailability()
  const { saveAvailability, loading: saveLoading } = useSaveAvailability()
  const history = useHistory()
  const [selectedAvail, setSelectedAvail] = useState(INITIAL_STATE)
  const classes = useStyles()

  const handleOptionClick = (day, time) => {
    const newSelected = { ...selectedAvail }
    newSelected[day][time] = !selectedAvail[day][time]
    setSelectedAvail(newSelected)
  }

  const handleSave = async () => {
    await saveAvailability({
      availability: selectedAvail,
      timeZone: volunteerAvailability.timeZone,
    })
    history.push('/lend-dashboard')
  }

  useEffect(() => {
    setSelectedAvail(volunteerAvailability.availability)
  }, [volunteerAvailability.availability])

  const loading = getLoading || saveLoading
  return (
    <Content>
      <Link to="/lend-dashboard" className={classes.backLink}>
        <ArrowBack />
        Back to Dashboard
      </Link>
      <h1>Availability</h1>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid container direction="column">
            <Grid item container direction="row">
              <Grid item xs={3} />
              <Grid item xs={3}>
                <p className={classes.label}>8-12pm</p>
              </Grid>
              <Grid item xs={3}>
                <p className={classes.label}>12-5pm</p>
              </Grid>
              <Grid item xs={3}>
                <p className={classes.label}>5-9pm</p>
              </Grid>
            </Grid>

            {DAYS_OF_WEEK.map((day, i) => (
              <Grid item container direction="row" key={day}>
                <Grid item xs={3}>
                  <p className={classes.label}>{day}</p>
                </Grid>
                <Grid item xs={3}>
                  <span
                    onClick={() => handleOptionClick(day, 'morning')}
                    className={cn(classes.option, { [classes.selected]: selectedAvail[day].morning })}
                  >
                    am
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <span
                    onClick={() => handleOptionClick(day, 'afternoon')}
                    className={cn(classes.option, { [classes.selected]: selectedAvail[day].afternoon })}
                  >
                    mid
                  </span>
                </Grid>
                <Grid item xs={3}>
                  <span
                    onClick={() => handleOptionClick(day, 'evening')}
                    className={cn(classes.option, { [classes.selected]: selectedAvail[day].evening })}
                  >
                    pm
                  </span>
                </Grid>
              </Grid>
            ))}
          </Grid>
          <Button className={classes.button} variant="contained" color="secondary" onClick={handleSave}>
            Save
          </Button>
        </>
      )}
    </Content>
  )
}
