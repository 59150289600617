import { FieldProps, getIn } from 'formik'
import React, { FC } from 'react'

import InputComponent, { Props as InputProps } from '../input'

export interface Props extends FieldProps, Partial<InputProps> {}

//TODO we should just be using Material-UI formik Fields here...
export const InputComponentField: FC<Props> = ({ field, form, ...props }) => {
  const error = getIn(form.errors, field.name)
  const hasError = !!error && getIn(form.touched, field.name)

  const handleChange = (value: string) => {
    form.setFieldValue(field.name, value, true)
  }

  return (
    <InputComponent
      {...props}
      value={field.value}
      onChange={handleChange}
      onBlur={field.onBlur}
      hasError={hasError}
      errorMessage={error}
    />
  )
}
