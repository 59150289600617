import { isEmpty } from 'lodash'
import React, { FC, useState } from 'react'
import { FieldProps, getIn } from 'formik'

import { Place } from './utils'
import useGoogleMapsAutocomplete from './useGoogleMapsAutocomplete'
import InputComponent from '../input'

export interface Props<FormValues = any> extends FieldProps<Place, FormValues> {
  label?: string
  placeholder?: string
}

//TODO doesn't honor the initial field.value property...
export const GoogleMapsAutocompleteField: FC<Props> = ({ field, form, ...props }) => {
  const error = getIn(form.errors, field.name)

  const [text, setText] = useState('')

  const handleOnChange = (value: string) => {
    if (field.value != null && isEmpty(value)) {
      form.setFieldValue(field.name, null, true)
    }

    setText(value)
  }

  const handlePlaceChange = (place: Place) => {
    form.setFieldValue(field.name, place, true)

    const newText = getIn(place, 'displayName', '')
    setText(newText)
  }

  const addressRef = useGoogleMapsAutocomplete(handlePlaceChange)

  return (
    <InputComponent
      {...props}
      inputRef={addressRef}
      type="search"
      value={text}
      onChange={handleOnChange}
      onBlur={field.onBlur}
      hasError={!!error}
      errorMessage={error}
    />
  )
}
