import React, { useState } from 'react'
import { makeStyles, Button } from '@material-ui/core'
import PersonAdd from '@material-ui/icons/PersonAdd'
import useTaskRecipients from '../../../../hooks/useTaskRecipients'
import { CreateRecipient } from './CreateRecipient'
import RadioSelect from '../../../../components/RadioSelect'
import StepContainer from '../StepContainer'

const useStyles = makeStyles((theme) => ({
  addNew: {
    marginBottom: '30px',
    marginTop: '30px',
    alignSelf: 'center',
  },
}))

export default function ChooseRecipient(props) {
  const { nextStep, value, onChange, signedInPerson } = props
  const [addingRecipient, setAddingRecipient] = useState(false)
  const { recipients, loading } = useTaskRecipients(signedInPerson)
  const classes = useStyles()

  const onSubmitRecipient = (newRecipient) => {
    onChange(newRecipient)
    nextStep()
  }

  if (addingRecipient) {
    return <CreateRecipient onSubmit={onSubmitRecipient} prevStep={() => setAddingRecipient(false)} />
  }
  return (
    <StepContainer
      header="Recipient"
      subHeader="Who needs Helping Hands support?"
      disabled={!value}
      nextStep={nextStep}
      loading={loading}
    >
      <RadioSelect
        options={recipients}
        value={value}
        onChange={onChange}
        getLabel={(recipient) =>
          recipient.id === signedInPerson.id ? 'I do' : recipient.givenName + ' ' + recipient.familyName
        }
      />
      <Button
        color="secondary"
        className={classes.addNew}
        onClick={() => setAddingRecipient(true)}
        startIcon={<PersonAdd style={{ fontSize: 26 }} />}
      >
        <div>
          <u>
            <b>New Person</b>
          </u>
        </div>
      </Button>
    </StepContainer>
  )
}
