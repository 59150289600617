import React from 'react'
import * as _ from 'lodash'
import RadioSelect from '../../../components/RadioSelect'
import StepContainer from './StepContainer'

export default function ChooseContact(props) {
  const { recipient, value, nextStep, signedInPerson, onChange } = props
  const recipientHasContact = recipient.phoneNumber || recipient.email
  const contacts = recipientHasContact ? _.uniqBy([{ ...signedInPerson }, recipient], 'id') : [{ ...signedInPerson }]

  return (
    <StepContainer
      header="Recipient"
      subHeader="Who should we contact about the task?"
      disabled={!value}
      nextStep={nextStep}
    >
      <RadioSelect
        options={contacts}
        value={value}
        onChange={onChange}
        getLabel={(contact) =>
          (recipient.id === contact.id) === signedInPerson.id
            ? 'Contact Me'
            : contact.givenName + ' ' + contact.familyName
        }
      />
    </StepContainer>
  )
}
