import React from 'react'
import AvailableTasks from '../volunteer/AvailableTasks'
import TaskInfo from '../volunteer/TaskInfo'
import LendDashboard from '../volunteer/LendDashboard'
import { Redirect } from 'react-router'
import UserPreferences from '../volunteer/UserPreferences'
import SignIn from '../auth/pages/SignIn'
import ResetPassword from '../auth/pages/ResetPassword'
import SignUp from '../auth/pages/SignUp'
import Organizations from '../volunteer/Organizations'
import ReceiveOrLend from '../volunteer/ReceiveOrLend'
import RequestTask from '../request/components/RequestTask'
import OrgDetails from '../volunteer/OrgDetails'
import ReceiveDashboard from '../volunteer/ReceiveDashboard'
import CreateOrLogin from '../auth/pages/CreateOrLogin'
import Message from '../components/Message'
import OptOut from '../components/opt-out'

export default function useRoutes() {
  return [
    { path: '/available-tasks', component: AvailableTasks },
    { path: '/tasks/:id', component: TaskInfo },
    { path: '/receive-dashboard', component: ReceiveDashboard },
    { path: '/lend-dashboard', component: LendDashboard },
    { path: '/user-preferences', component: UserPreferences },
    { path: '/organizations', component: Organizations },
    { path: '/organization/:orgId', component: OrgDetails },
    { path: '/login', component: CreateOrLogin, public: true },
    { path: '/sign-in', component: SignIn, public: true },
    { path: '/sign-up', component: SignUp, public: true },
    { path: '/reset-password', component: ResetPassword, public: true },
    { path: '/receive-or-lend', component: ReceiveOrLend },
    { path: '/request-task', component: RequestTask },
    { path: '/message/:uuid/recipient/:recipientId', component: Message, public: true },
    { path: '/opt-out/:token', component: OptOut, public: true },
    // since this is not "exact: true", it must be at the bottom to avoid unwanted redirects
    { path: '/', component: () => <Redirect to="/receive-or-lend" /> },
  ]
}
