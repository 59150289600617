import { useCallback } from "react"
import { useSnackbar } from "notistack"

const useSuccessNotification = () => {
    const { enqueueSnackbar } = useSnackbar()

    return useCallback( msg => {
        return enqueueSnackbar(msg, {
            variant: 'success',
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            }
        })
    }, [enqueueSnackbar])
}

export default useSuccessNotification