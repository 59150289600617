import { useCallback } from 'react'
import { useFetch } from '../api/hooks'
import useSuccessNotification from 'civic-champs-shared/api/hooks/useSuccessNotification'

export const TaskEventType = {
  REQUESTED: 'requested',
  REQUESTER_CANCELED: 'requester-canceled',
  ADMIN_CANCELED: 'admin-canceled',
  TASK_ACCEPTED: 'task-accepted',
  TASK_DECLINED: 'task-declined',
  DETAILS_NEEDED: 'details-needed',
  DETAILS_UPDATED: 'details-updated',
  VOLUNTEER_ASSIGNED: 'volunteer-assigned',
  VOLUNTEER_ASSIGNED_SELF: 'volunteer-assigned-self',
  VOLUNTEER_REMOVED: 'volunteer-removed',
  VOLUNTEER_DROPPED: 'volunteer-dropped',
  VOLUNTEER_RESCHEDULE_REQUEST: 'volunteer-reschedule-request',
  RECIPIENT_RESCHEDULE_ACCEPTED: 'recipient-reschedule-accepted',
  RECIPIENT_RESCHEDULE_DECLINED: 'recipient-reschedule-declined',
  RECIPIENT_RESCHEDULE_REQUEST: 'recipient-reschedule-request',
  VOLUNTEER_RESCHEDULE_ACCEPTED: 'volunteer-reschedule-accepted',
  VOLUNTEER_RESCHEDULE_DECLINED: 'volunteer-reschedule-declined',
  ADMIN_RESCHEDULE_REQUEST: 'admin-reschedule-request',
  TASK_STARTED: 'task-started',
  TASK_COMPLETED: 'task-completed',
  COMPLETION_CONFIRMED: 'completion-confirmed',
  COMPLETION_DENIED: 'completion-denied',
  TASK_UNCOMPLETABLE: 'task-uncompletable',
  PROVIDE_RECIPIENT_FEEDBACK: 'recipient-feedback',
}

const makeEventHook = ({ eventCode, errorMessage = 'Error updating task', successMessage }) => () => {
  const [request, { loading }] = useFetch(errorMessage)
  const showSuccess = useSuccessNotification()
  const taskEventCall = useCallback(
    async (task, payload = {}) => {
      return request({
        method: 'put',
        url: `/organizations/${task.organization.id}/tasks/${task.id}`,
        onSuccess: () => successMessage && showSuccess(successMessage),
        config: {
          body: {
            taskId: task.id,
            eventCode,
            ...payload,
          },
        },
      })
    },
    [request, eventCode, successMessage, showSuccess],
  )
  return [taskEventCall, loading]
}

export const useAcceptTask = makeEventHook({
  eventCode: TaskEventType.TASK_ACCEPTED,
  errorMessage: 'Error accepting the task',
  successMessage: 'Successfully accepted the task',
})
export const useAdminCancelTask = makeEventHook({
  eventCode: TaskEventType.ADMIN_CANCELED,
  errorMessage: 'Error canceling the task',
  successMessage: 'Successfully canceled the task',
})
export const useRemoveVolunteerFromTask = makeEventHook({
  eventCode: TaskEventType.VOLUNTEER_REMOVED,
  errorMessage: 'Error removing the volunteer',
  successMessage: 'Successfully removed the volunteer',
})
export const useCompleteTask = makeEventHook({
  eventCode: TaskEventType.TASK_COMPLETED,
  errorMessage: 'Error completing the task',
  successMessage: 'Successfully completed the task',
})
export const useConfirmCompleteTask = makeEventHook({
  eventCode: TaskEventType.COMPLETION_CONFIRMED,
  errorMessage: 'Error completing the task',
  successMessage: 'Successfully completing the task',
})
export const useAssignVolunteerTask = makeEventHook({
  eventCode: TaskEventType.VOLUNTEER_ASSIGNED,
  errorMessage: 'Error assigning the task',
  successMessage: 'Successfully assigned the task',
})
export const useAssignSelfTask = makeEventHook({
  eventCode: TaskEventType.VOLUNTEER_ASSIGNED_SELF,
  errorMessage: 'Error claiming the task',
  successMessage: 'Successfully claimed the task',
})
export const useStartTask = makeEventHook({
  eventCode: TaskEventType.TASK_STARTED,
  errorMessage: 'Error starting the task',
  successMessage: 'Successfully started the task',
})
export const useVolunteerDropTask = makeEventHook({
  eventCode: TaskEventType.VOLUNTEER_DROPPED,
  errorMessage: 'Error withdrawing from the task',
  successMessage: 'Successfully withdrawn from the task',
})

export const useCancelTask = makeEventHook({
  eventCode: TaskEventType.REQUESTER_CANCELED,
  errorMessage: 'Error cancelling from the task',
  successMessage: 'Successfully cancelled the task',
})

export const useProvideRecipientFeedback = makeEventHook({
  eventCode: TaskEventType.PROVIDE_RECIPIENT_FEEDBACK,
  errorMessage: 'Error providing task for the task',
  successMessage: 'Successfully provided feedback for the task',
})

export const useVolunteerRescheduleRequest = makeEventHook({
  eventCode: TaskEventType.VOLUNTEER_RESCHEDULE_REQUEST,
  errorMessage: 'Error updating task timeslot',
  successMessage: 'Successfully updated task',
})
