import Loading from '../components/Loading'
import Content from '../components/Content'
import { makeStyles } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import React from 'react'
import { useHistory } from 'react-router'
import StepContainer from '../request/components/Steps/StepContainer'
import NextButton from '../components/NextButton'
import { TextField } from 'formik-material-ui'
import { useProvideRecipientFeedback } from '../hooks/useTaskEvents'
import yup from '../utils/yup'
import VolunteerRatingField from './VolunteerRatingField'

const initialValues = {
  recipientFeedbackReflection: '',
  recipientFeedbackRating: null,
}

const useStyles = makeStyles(() => ({
  input: {
    width: '324px',
    height: '354px',
    borderColor: '#707070',
  },
}))

const validationSchema = yup.object({
  recipientFeedbackReflection: yup.string().min(2).required(),
  recipientFeedbackRating: yup.number().oneOf([-1, -0.5, 0.5, 1]).required(),
})

export default function RecipientFeedback(props) {
  const { task } = props
  const [provideFeedback, loading] = useProvideRecipientFeedback()
  const history = useHistory()
  const classes = useStyles()

  const handleFormikSubmit = async (values) => {
    const payload = {
      recipientFeedbackReflection: values.recipientFeedbackReflection,
      recipientFeedbackRating: values.recipientFeedbackRating,
    }
    await provideFeedback(task, payload)
    history.push('/receive-dashboard')
  }

  if (loading) {
    return (
      <Content>
        <Loading />
      </Content>
    )
  }
  return (
    <Content>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => handleFormikSubmit(validationSchema.cast(values))}
        validationSchema={validationSchema}
        validateOnMount={true}
      >
        {({ isValid }) => {
          return (
            <Form>
              <StepContainer header="Completed Task" disabled={!isValid}>
                <Field
                  component={TextField}
                  multiline
                  rows={15}
                  rowsMax={20}
                  name="recipientFeedbackReflection"
                  placeholder="Please add your feedback here"
                  className={classes.input}
                  variant="outlined"
                  label={`How did ${task.volunteer.givenName} do today helping you?`}
                />
                <Field
                  component={VolunteerRatingField}
                  name="recipientFeedbackRating"
                  label="How was your experience today?"
                />
                <NextButton text="Send Feedback" type="submit" disabled={!isValid} />
              </StepContainer>
            </Form>
          )
        }}
      </Formik>
    </Content>
  )
}
