import { Button, Dialog, DialogContent, makeStyles } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import { CheckboxWithLabel } from 'formik-material-ui'
import React from 'react'
import yup from '../utils/yup'

const HEALTH_CHECK_QUESTIONS = [
  {
    name: 'symptoms',
    question: 'I am not exhibiting any symptoms of COVID-19 (cough, fever, etc)',
  },
  {
    name: 'travel',
    question: 'I have not travelled internationally, to NY, NJ, or CT in the past 14 days.',
  },
  {
    name: 'distancing',
    question: 'I have been practicing social distancing',
  },
  {
    name: 'age',
    question: 'I am between the ages of 16-40 years old',
  },
  {
    name: 'guidelines',
    question: 'I have read and will follow the CDC guidelines regarding hygiene',
  },
  {
    name: 'conditions',
    question: 'I do not have any underlying medical conditions that increase my risk from COVID-19',
  },
]

const initialValues = HEALTH_CHECK_QUESTIONS.reduce((acc, healthCheckQuestion) => {
  acc[healthCheckQuestion.name] = false
  return acc
}, {})

const healthValidationSchema = yup.object(
  HEALTH_CHECK_QUESTIONS.reduce((acc, healthCheckQuestion) => {
    acc[healthCheckQuestion.name] = yup.boolean().oneOf([true])
    return acc
  }, {}),
)

const useStyles = makeStyles(() => ({
  center: {
    textAlign: 'center',
  },
}))

const HealthCheckDialog = (props) => {
  const { onClose, open, onSubmit, task } = props
  const classes = useStyles()
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Formik initialValues={initialValues} validationSchema={healthValidationSchema} onSubmit={onSubmit}>
          {({ isValid, isSubmitting, dirty }) => {
            return (
              <Form>
                <h1>Verify Wellness</h1>
                {parseInt(process.env.REACT_APP_WFS_ORG_ID) === task.organization.id ? (
                  <p>
                    Before leaving for your visit, screen yourself to make sure you are not exhibiting any symptoms of
                    COVID-19. Call the senior as well to confirm the visit and ask them screening questions. These are
                    the questions to ask yourself, and to ask the senior when you call. Significant contact is defined
                    as being within six feet for at least 15 cumulative minutes.
                  </p>
                ) : (
                  <p>To ensure the safety of everyone we need to affirm a few Covid-19 related precautions.</p>
                )}
                <h2>Please affirm the following:</h2>
                {HEALTH_CHECK_QUESTIONS.map((q) => (
                  <Field
                    key={q.name}
                    component={CheckboxWithLabel}
                    type="checkbox"
                    name={q.name}
                    Label={{ label: q.question }}
                  />
                ))}

                <div className={classes.center}>
                  <Button
                    variant="contained"
                    color="secondary"
                    disabled={!isValid || isSubmitting || !dirty}
                    className={classes.submit}
                    type="submit"
                  >
                    Submit
                  </Button>
                </div>
              </Form>
            )
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  )
}

export default HealthCheckDialog
