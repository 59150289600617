import React from 'react'
import { Button, Grid } from '@material-ui/core'
import { stepTwoSchema } from './schemas'
import { Field, Formik, Form } from 'formik'
import { TextField } from 'formik-material-ui'
import PasswordField from '../../../components/PasswordField'

function StepTwo(props) {
  const { onSubmit, classes, profile } = props
  const contact = profile ? (profile.email ? 'email' : 'phone') : 'email/phone'
  return (
    <Formik
      initialValues={{
        code: '',
        password: '',
        passwordConfirm: '',
      }}
      validationSchema={stepTwoSchema}
      onSubmit={(values) => onSubmit(stepTwoSchema.cast(values))}
      validateOnBlur
    >
      {({ dirty, isValid, values, errors }) => (
        <Form>
          <Grid container spacing={2} direction="column" justify="center" alignItems="center">
            <p>You should have been sent a verification code. Please check your {contact}</p>
            <div className={classes.title}>
              <b>Verification Code</b>
            </div>
            <Field
              component={TextField}
              name="code"
              placeholder="Verification Code"
              className={classes.input}
              variant="outlined"
            />
            <div className={classes.title}>
              <b>Password*</b>
            </div>
            <Field
              component={PasswordField}
              name="password"
              variant="outlined"
              className={classes.input}
              placeholder="Password"
            />
            <div className={classes.title}>
              <b>Confirm Password*</b>
            </div>
            <Field
              component={PasswordField}
              name="passwordConfirm"
              variant="outlined"
              placeholder="Confirm Password"
              className={classes.input}
            />
            <Button className={classes.buttonLogin} variant="contained" disabled={!dirty || !isValid} type="submit">
              <div className={classes.loginText}>
                <b>Submit</b>
              </div>
            </Button>
          </Grid>
        </Form>
      )}
    </Formik>
  )
}

export default StepTwo
